import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadLogSegment } from '../../redux/admin/EventActions'
import _ from 'lodash'
import { getEmailtasks, getEvent, getIsSendingUserEmail, getLogs, getPrevEmails, getSmstasks } from '../../redux/admin/EventReducer'
import EventLogs from './components/EventLogs'
import EmailSent from './components/EmailSent'
import SmsSent from './components/SmsSent'
import PrevMails from './components/PrevEmails'






export default function LogTabs(props) {
 
const dispatch = useDispatch()
const event = useSelector(getEvent)
const logs = useSelector(getLogs)
const sms = useSelector(getSmstasks)
const mails = useSelector(getEmailtasks)
const prevEmails = useSelector(getPrevEmails)
const isSendingUserEmail=useSelector(getIsSendingUserEmail)
const logTabs=[
    {
        key :"email_logs",
        label :"Email Logs",
        children :<EmailSent mails ={mails}/>
    
    },
    {
        key :"sms_logs",
        label :"Sms Logs",
        children :<SmsSent sms ={sms}/>

    
    },
    {
        key :"email_to_user",
        label :"Email to user",
        children :<PrevMails 
        isSending ={isSendingUserEmail}
        send ={props.send}
        mails ={prevEmails}/>

    
    },
    {
        key :"whatsapp_logs",
        label :"Whatsapp Logs",
        // children :<EmailSent mails ={mails}/>

    
    }
]

    useEffect(()=>{
    dispatch(loadLogSegment(event))
},[])  
  return (
    <div>
   <Tabs
   tabPosition={"left"}
   items ={logTabs}
   />
   <EventLogs
                                    logs={logs}
                                /> 
   </div>
   
  )
}
