import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Pubnub from 'pubnub';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOnlineUsers } from './redux/admin/OnlineCustomerActions';
import { getPubnubCredentials } from './redux/admin/OnlineCustomerReducer';
import callEventApi from './util/eventApiCaller';
import { fetchSalesAgentQueueData, fetchSalesAgentQueueDataV2 } from './redux/admin/SalesAgentQueueActions';
import {
    CHANGE_AGENT_FOR_EVENT,
    IS_USER_ONLINE_OFFLINE,
    NEW_EVENT,
    SHORTLIST_VENUE_BY_CUSTOMER,
    AGENT_LOGOUT
} from "./constants/realtimeEventUpdates";
import EventModalForm from './components/EventModalForm/EventModalForm';
import {
    closeEditPackageForm,
    closeQueryForVenueList,
    fetchEventInfo,
    toggleEventModalForm
} from './redux/admin/EventActions';
import { getEventModalState, getEventSelectedTab } from './redux/admin/EventReducer';
import { getActiveAgent } from './redux/admin/UserReducer';
import { io } from "socket.io-client";
import { PUBNUB, SOCKET_IO } from "./constants/rtcEnums";
import showToastMessage from "./util/notificationUtil";
import { getAllLeadsFiltersVisible } from "./redux/admin/AllLeadsReducer";
import { fetchAllLeads } from "./redux/admin/AllLeadActions";
import { getAvailaibilityCalendarVisibilityState } from './redux/admin/AvailaibilityCalendarReducer';
import CalendarDrawer from './components/EventModalForm/components/CalendarDrawer';
import { setAgent } from './redux/admin/UserActions';
import { fetchnotificationcount, notificationCount } from './redux/admin/AppActions';

// import alertAudio from '../assets/alert.wav'

export const GLOBAL_CHANNEL = 'global';
let socket = null;

const Connector = ({agentid,...props}) => {
    const dispatch = useDispatch();

    const isEventModalVisible = useSelector(getEventModalState);
    const selectedTab = useSelector(getEventSelectedTab);
    const activeAgent = useSelector(getActiveAgent);
    const isAllLeadsFilterVisible = useSelector(getAllLeadsFiltersVisible);
    const availaibilityCalendarVisible = useSelector(getAvailaibilityCalendarVisibilityState);

    const [credentials, setCredentials] = useState({});
    const [channels, setChannels] = useState([GLOBAL_CHANNEL]);


    // const usePrevious = (value) => {
    //     const ref = useRef();
    //     useEffect(() => {
    //         ref.current = value;
    //     });
    //     return ref.current;
    // }

    // const prevProps = usePrevious({ agentid });
    useEffect(() => {
        dispatch(setAgent(props.agent))
        callEventApi(`config`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                console.log("config_res", res);

                let newChannel = [agentid, GLOBAL_CHANNEL]
                if (res.data.rtc_service === SOCKET_IO) {
                    const token = window.sessionStorage.getItem("token");
                    console.log(token, process.env.REACT_APP_CHAT_API_URL);
                    socket = io.connect(`${process.env.REACT_APP_CHAT_API_URL}`, {
                        query: { token },
                        reconnect: true
                    });
                    console.log("socket1", socket);
                    socket.on('connect', () => {
                        console.log('Socket connected successfully');
                    });
                    if (socket) {
                        socket.on('event', (msg) => {
                            listenForSocketIOMessage(msg,newChannel);
                            
                        });
                    }
                } else if (res.data.rtc_service === PUBNUB) {
                    const newCredentials = {
                        publishKey: res.data.pubnub_publisher_key,
                        subscribeKey: res.data.pubnub_subscriber_key,
                        uuid: `agent-${agentid}`
                    }
                    setCredentials(newCredentials);
                    const hereNowObject = {
                        channels: [agentid, GLOBAL_CHANNEL],
                        includeUUIDs: true,
                        includeState: false
                    };
                    const pubnub = new Pubnub(credentials);
                    pubnub.hereNow(hereNowObject)
                        .then(success => {
                            dispatch(fetchOnlineUsers(success));
                        }).catch(err => {
                            console.log('error occured while getting online users >> pubnub', err);
                        });
                    pubnub.subscribe({
                        channels: channels
                    });
                    pubnub.addListener({
                        message: (msg) => {
                            getMessages(msg.message);
                        },
                    });
                }
            }
        }).catch(err => {
            if (err) {
                console.log(err);
            }
        });
        checkForOnlineUsers();
    }, []);

    const listenForSocketIOMessage = (msg,channel) => {
        // todo only use when development window.socketDebug = true;
        if (window && window.socketDebug) {
            console.log('socket.io', msg);
        }
        if (channel?.includes(msg?.channel_id)) {
            getMessages(msg?.message);
        }
    }

    const logoutTheAgent = (agentId) => {
        if (agentId === agentid) {
            localStorage.clear();
            // isAuthenticated = false;
            window.location = '/';
        }
    }

    const getMessages = (msg) => {
        console.log(msg, 'msg');
        switch (msg.event) {
            // case NEW_EVENT:
            //     console.log("changed");
            //     showToastMessage("There are new leads", 'success');
            //     if (!isAllLeadsFilterVisible) {
            //         let filters = {};
            //         filters.pageNum = 1;
            //         filters.pageSize = 50;
            //         filters.filters = {};
            //         dispatch(fetchAllLeads(filters));
            //     }
            //     // dispatch(fetchSalesAgentQueueDataV2(agentid, {
            //     //     "pageNum": 1,
            //     //     "pageSize": 50,
            //     //     "isMoreFiltersAllowed": false,
            //     //     "isSearching": false
            //     // }))
            //     break;

            // case SHORTLIST_VENUE_BY_CUSTOMER:
            //     dispatch(fetchEventInfo(msg.event_id))
            //     break;

            // case CHANGE_AGENT_FOR_EVENT:       
            //         showToastMessage("You Have New Enquiry", 'success');
            //         dispatch(fetchnotificationcount('agent',agentid))
                     
            //         const audio = new Audio("https://cdn.venuemonk.com/alert.wav");
            //         // console.log(audio)
            //         audio.play();
            //     // dispatch(fetchSalesAgentQueueDataV2(agentid, {
            //     //     "pageNum": 1,
            //     //     "pageSize": 50,
            //     //     "isMoreFiltersAllowed": false,
            //     //     "isSearching": false
            //     // }))
            //     break;

            case IS_USER_ONLINE_OFFLINE:
                checkForOnlineUsers(msg.event_id)
                break;

            case AGENT_LOGOUT:
                logoutTheAgent(msg.agent_id)
                break;

            default: {
                console.log(msg)
                dispatch(fetchnotificationcount('agent',agentid))             
                createTostPopUp(msg.notification)   
                const audio = new Audio("https://cdn.venuemonk.com/alert.wav");
                audio.play();

            }
        }
    }

    const createTostPopUp = (notification) => {
        showToastMessage( <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // width:"500px"
                      }}
                    >
                      <div
                        key={notification._id}
                        style={{
                          padding: "20px",
                        }}
                      >
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {notification.title}
                        </span>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          {notification.body}{" "}
                          <p
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "row",
                              gap: "30px",
                            }}
                          >
                            {" "}
                            {/* <b>
                              {dayjs(notification.created_on).fromNow()}
                            </b>{" "} */}
                            <a
                              href={`${notification.payload.url}`}
                              style={{ textDecoration: "none" }}
                            >
                              {" "}
                              <i
                                title="Open Enquiry"
                                className="fa fa-external-link"
                              ></i>
                            </a>
                          </p>
                        </p>
                      </div>
                    </div>,
                    notification.notification_type,notification.isTimedNotification,notification.timer);
    }

    const checkForOnlineUsers = () => {
        dispatch(fetchOnlineUsers())
    }

    const closeEventModal = (isTableRefreshNeeded) => {
        dispatch(toggleEventModalForm(false, 1));
        dispatch(closeEditPackageForm());
        dispatch(closeQueryForVenueList());
    };

    return (
        <div style={{ color: "green" }}>
            {isEventModalVisible ?
                <EventModalForm
                    selectedTab={selectedTab}
                    showModal={isEventModalVisible}
                    close={closeEventModal}
                    showMessage={props.showMessage}
                    userPermissions={props.userPermissions}
                    cities={props.cities}
                    agentid={props.agentid}
                />
                :
                null
            }
            {availaibilityCalendarVisible && (
                <CalendarDrawer
                    userPermissions={props.userPermissions}
                    open={availaibilityCalendarVisible}
                />
            )}
        </div>
    );
}

export default Connector;
