import React, {Component} from 'react';
import ManagerSearchHeader from '../../modules/ManagerSearchHeader/ManagerSearchHeader';
import UserDropdown from './UserDropdown';
import PubNubReact from 'pubnub-react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import NotificationWrapper from "../../components/Notifications/NotificationWrapper";
import {configApiCall, fetchOnlineAgents} from '../../redux/admin/OnlineCustomerActions';
import {getPubnubCredentials} from '../../redux/admin/OnlineCustomerReducer';
import {connect} from 'react-redux';
import {clearAllLocalStorageKeys} from "../../redux/admin/UserActions";
import { Select } from 'antd';
import { BREAK_OPTIONS } from '../../constants/breakEnums';
import { Button } from 'antd';
import { getNotificationCount, getNotificationList } from '../../redux/admin/AppReducer';
import { getAppBarUtilityClass } from '@mui/material';
import NotificationList from '../../modules/Admin/AdminNotifications/components/NotificationList';
import { fetchnotificationcount, fetchnotificationlist, notificationAllMarkRead } from '../../redux/admin/AppActions';
import NotificationModal from './NotificationModal';
import zIndex from '@material-ui/core/styles/zIndex';

class Header extends Component {
    constructor() {
        super();
        this.state = {
            isUserDropdownOpen: false,
            isNotificationModalOpen: true,
            isSidebarHidden: false,
            notificationCount: 0,
            break_text:null,
            break_code:null,
            isModalOpen: false,
            notifications: [
              // Sample notifications
              { message: 'New message from John' },
              { message: 'Meeting scheduled for tomorrow' },
              // Add more notifications as needed
            ],
            
        };
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-c4fa1fec-6b22-4fa2-92e0-26c82ae810ae',
            subscribeKey: 'sub-c-974d3f7a-d31f-11e5-a9b2-02ee2ddab7fe'
        });
        this.pubnub.init(this);
    }



    handleBellClick = () => {
        this.setState({ isModalOpen: true });
      };
    
      handleCloseModal = () => {
        this.setState({ isModalOpen: false });
      };
    componentDidMount() {
  this.props.dispatch(fetchnotificationlist('agent', this.props.user._id, 1))
  this.props.dispatch(fetchnotificationcount('agent', this.props.user._id))
        document.body.classList.toggle('sidebar-collapsed', false);
        if (this.props.user && this.props.user._id) {
            this.pubnub.subscribe({
                channels: [`${this.props.user._id}`],
                withPresence: true
            });
            this.pubnub.getMessage(`${this.props.user._id}`, (msg) => {
            
                let message = {};
                message.text = msg.message;
                message.timer = 3000;
                message.notification_type = 'success';
                message.isTimedNotification = true;
                // this.props.showMessage(msg.message.text, 'success');
                this.props.notifyToAdmin(message, '/manager/funnel/sales-board', 3);
            });
        }

        if (_.isEmpty(this.props.pubnubCredentials)) {
            this.props.dispatch(configApiCall())
        }
        // this.props.dispatch(fetchOnlineAgents([this.props.agentid,'dummy'], this.props.pubnubCredentials));
    }

    componentDidUpdate(prevProps) {
        if (_.isEmpty(prevProps.user) && !_.isEmpty(this.props.user)) {
            this.pubnub.subscribe({
                channels: [`${this.props.user._id}`],
                withPresence: true
            });
            this.pubnub.getMessage(`${this.props.user._id}`, (msg) => {
                this.props.fetchAgentUnReadMessageCount(this.props.user._id, (err, count) => {
                    console.log(count);
                    this.setState({
                        notificationCount: count
                    });
                });
                let message = {};
                message.text = msg.message;
                message.timer = 3000;
                message.notification_type = 'success';
                message.isTimedNotification = true;
                // this.props.showMessage(msg.message.text, 'success');
                this.props.notifyToAdmin(message, '/manager/funnel/sales-board', 3);
            });
        }

        if (prevProps.pubnubCredentials !== this.props.pubnubCredentials) {
            // this.props.dispatch(fetchOnlineAgents([this.props.agentid,'dummy'], this.props.pubnubCredentials));
        }
    }

    clearAllLocalCache = () => {
        this.props.dispatch(clearAllLocalStorageKeys());
    }

    componentWillUnmount() {
        document.body.classList.remove('sidebar-collapsed');
        this.pubnub.unsubscribe({
            channels: [`${this.props.user._id}`]
        });
    }

    toggleUserDropdown = () => {
        this.setState({
            isUserDropdownOpen: !this.state.isUserDropdownOpen
        });
    };

    toggleSidebar = () => {
        this.setState({
            isSidebarHidden: !this.state.isSidebarHidden
        }, () => {
            document.body.classList.toggle('sidebar-collapsed', this.state.isSidebarHidden)
        });
    };
    breakChangeHandler=(_,options)=>{
     this.setState({
        break_code:options?.reason_code?options?.reason_code:null,
        break_text:options?.value? options?.value : null
     })
    }

    handleBreakApply=()=>{
   if(this.state.break_code && this.state.break_text){
    this.props.logOut("break",this.state.break_text,this.state.break_code)
   }
    

}

handleBellClick = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

   notificationAllMark = () => {
    let id= this.props.user._id
    if (id) {
      this.props.dispatch(notificationAllMarkRead('agent', id,1));
    } else {
      console.log(" ID is missing");
    }
  };

    render() {

        const { isModalOpen, notifications, notificationCount } = this.state;


        const modalStyles = {
            overlay: {
              position: 'fixed',
              top: '50px',
              right: 0,
              width: '100vw',
              height: '100vh',
              background: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'flex-end',
            //   alignItems: 'center',
            },
            modal: {
                marginTop:'30px',
              width: '600px',
              height: '500px',
              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              overflowY: 'auto',
              position: 'relative',
              padding: '20px',
              zIndex:1001
            },
            closeButton: {
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '18px',
              cursor: 'pointer',
            },
            content: {
              display:'flex',
              flexDirection:'row',
            gap:'40px'
            //   justifyContent: 'center'
            },
          };







        return (
            <div>
                <div className="logo-area" style={{width:'max-content'}}>
    <span id="trigger-sidebar" className="toolbar-trigger toolbar-icon-bg">
      <a onClick={() => this.toggleSidebar()} data-toggle="tooltips" data-placement="right" title="Toggle Sidebar">
        <span className="icon-bg">
          <i className="ti ti-menu"/>
        </span>
      </a>
    </span>
                    <a style={{marginTop: '12px'}} href="/">
                        <img src="https://venuemonk-images.s3.amazonaws.com/images/logo-v3.png" alt="VenueMonk Logo"/>
                    </a>
                </div>
                {/* logo-area */}
                <div className="toolbar-icon-bg hidden-xs" id="toolbar-search">
                    <ManagerSearchHeader/>
                </div>
                <ul className="nav navbar-nav toolbar pull-right" style={{display:"flex" ,alignItems:"center"}}>

                <li>

<Select style={{width:"120px"}} allowClear onClear={this.breakChangeHandler} value={this.state.break_text} onSelect={this.breakChangeHandler}  options={BREAK_OPTIONS} placeholder="Break"></Select>
</li>
                <li>
                
<Button type='primary' onClick={this.handleBreakApply} disabled={this.state.break_text==null}>Take Break </Button>
</li>
                    <li className="dropdown toolbar-icon-bg">
                        <a
                            onClick={() => this.clearAllLocalCache()}
                        ><span className="icon-bg">
          <i className="ti ti-trash"/></span>
                        </a>
                    </li>
                    <li className="dropdown toolbar-icon-bg">
                        <a
                            onClick={() => this.props.clearAgentNotifications()}
                        ><span className="icon-bg">
          <i className="ti ti-close"/></span>
                        </a>
                    </li>
                    <li className="dropdown toolbar-icon-bg">
            <a onClick={this.handleBellClick}>
              <span className="icon-bg">
                <i className="ti ti-bell" />
              </span>
              <span
                id="vm-manager-notifications"
                className={this.props.notificationCount > 0 ? "badge badge-deeporange" : "badge badge-deeporange hide"}
              >
                {this.props.notificationCount}
              </span>
            </a>
          </li>
          {isModalOpen && (
          <div style={modalStyles.overlay} onClick={this.handleCloseModal}>
            <div style={modalStyles.modal} onClick={e => e.stopPropagation()}>
              <div style={modalStyles.content}>

           <NotificationModal 
           userid={this.props.user._id}
           onclose={this.handleCloseModal}
           notificationAllMark={this.notificationAllMark}
           />
           </div>
            </div>
          </div>
        )}
                   
                    <li className={this.state.isUserDropdownOpen ? "dropdown toolbar-icon-bg open" : "dropdown toolbar-icon-bg"}>
                        <a onClick={() => this.toggleUserDropdown()} className="dropdown-toggle username"
                           data-toggle="dropdown">
                            <span className="icon-bg"><i className="ti ti-user"/></span>
                        </a>
                        <UserDropdown
                            logOut={this.props.logOut}
                        />
                    </li>
                </ul>
                <NotificationWrapper
                    notification={this.props.notification}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pubnubCredentials: getPubnubCredentials(state),
        notificationCount: getNotificationCount(state),
        list :getNotificationList(state)

    }
}

export default connect(mapStateToProps)(Header);
