import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import AvailablityCalendar from "./Calendar/AvailabilityCalendar";
import { useDispatch, useSelector } from "react-redux";
import { getAvailaibilityCalendarSelectedVenue, getAvailaibilityCalendarVisibilityState } from "../../../redux/admin/AvailaibilityCalendarReducer";
import {
  setAvailabilityCalendarVisibility,
  setAvailaibilityCalendarSelectedVenue,
} from "../../../redux/admin/AvailaibilityCalendarActions";
import _ from "lodash";
import { getOperationalCityList } from "../../../redux/admin/AppReducer";
import VenueSearchComponent from "../../SelectVenueComponent";
export default function CalendarDrawer({ userPermissions }) {
  
  const dispatch = useDispatch();
  const selectedVenue = useSelector(getAvailaibilityCalendarSelectedVenue);
  const open = useSelector(getAvailaibilityCalendarVisibilityState)
  const cities = useSelector(getOperationalCityList);
  const [searchVenue, setSearchVenue] = useState(false);

  const onSelectVenueChange = (venue) => {
    setSearchVenue(false);
    dispatch(setAvailaibilityCalendarSelectedVenue(venue));
  };

  const handleClose = () => {
    dispatch(setAvailaibilityCalendarSelectedVenue({}))
    dispatch(setAvailabilityCalendarVisibility(false));
  };

  useEffect(() => {
    if (_.isEmpty(selectedVenue)) {
      setSearchVenue(true);
    }
  }, []);

  const headerStyle = () =>
    searchVenue
      ? {}
      : {
          display: "flex",
          justifyContent: "space-between",
        };

  const handleChangeVenue = () => {
    setSearchVenue(true);
    dispatch(setAvailaibilityCalendarSelectedVenue({}));
  };

  const header = (
    <div style={headerStyle()}>
      <div>{!_.isEmpty(selectedVenue) && selectedVenue.name}</div>
      <div>
        {searchVenue ? (
          <div>
            <VenueSearchComponent
              header={
                <span style={{ marginBottom: "10px", textAlign: "start" }}>
                  Search venue
                </span>
              }
              selectStyle={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              cities={cities}
              onSelectVenueChange={onSelectVenueChange}
            />
          </div>
        ) : (
          <Button onClick={() => handleChangeVenue()}>Change Venue</Button>
        )}
      </div>
    </div>
  );

  return (
    <Drawer
    width={"80%"}
    placement="right"
    onClose={handleClose}
    visible={open}
    title={header}
  >
    {!_.isEmpty(selectedVenue) && (
      <AvailablityCalendar
        userPermissions={userPermissions}
        venue={selectedVenue}
      />
    )}
  </Drawer>
  );
}
