import {isEmpty} from "lodash";
import {
    SET_EVENT_ID,
    TOGGLE_EVENT_MODAL_FORM,
    FETCH_EVENT_INFO_EVENT_ACTION,
    FETCH_PREVIOUS_EVENTS,
    FETCH_EVENT_BUNDLE,
    UPDATE_FIRST_SEEN_EVENT_STATS,
    FETCH_CORPORATE_EVENT_DETAILS,
    FETCH_BASIC_PARAMETERS,
    FETCH_LOCATION_PARAMETERS,
    FETCH_EVENT_TEMPLATES,
    FETCH_EVENT_STATS,
    FETCH_FEEDBACK,
    FETCH_ASSETS,
    FETCH_VENUE_BUNDLE_LIST,
    RELOAD_BUNDLE,
    RELOADING_BUNDLE,
    FETCH_VENUE_SEGMENT_BY_ID, SET_EVENT_LOADING_STEP,
    FETCHING_LOGS,
    FETCH_LOGS,
    FETCH_TASKS,
    FETCH_SMS_TASKS,
    FETCH_EMAIL_TASKS,
    CONVERTING_LEAD,
    CONVERT_LEAD,
    FETCH_VENUE_LIST,
    UPDATE_EVENT_INFO,
    UPDATING_EVENT_INFO,
    FIND_AGENT,
    FINDING_AGENT,
    EDIT_PACKAGE,
    SAVING_PACKAGES,
    PLAN_VISIT,
    UPDATE_VISIT,
    EDIT_VISIT_FORM,
    SCHEDULE_VISIT,
    SEND_USER_EMAIL,
    NOTIFY_VENUES,
    AGENT_OPTIONS,
    QUERY_LIST,
    SMS_TO_VENUE,
    SMS_TO_USER,
    QUERY_BUNDLE_SET,
    FETCH_RECOMMENDED_VENUES,
    SELECTED_VENUE,
    ADD_CUSTOMER_EVENT_NOTE_LIST,
    ADDING_CUSTOMER_EVENT_NOTE,
    LOAD_CUSTOMER_EVENT_NOTE_LIST,
    REMOVING_CUSTOMER_EVENT_NOTE_LIST,
    REMOVE_CUSTOMER_EVENT_NOTE_LIST,
    UPDATE_CUSTOMER_EVENT_NOTE_LIST,
    UPDATING_CUSTOMER_EVENT_NOTE_LIST,
    REMOVE_EVENT_INFO,
    EVENT_CALL_LOGS
} from "./EventActions";

const initialState = {
    isEventModalVisible: false,
    selectedTab: 1,
    selectedEventId: "",
    event: {},
    logs: [],
    tasks: [],
    task_count: 0,
    emailtasks: [],
    smstasks: [],
    recoList: [],
    recoListCombined: [],
    venueList: [],
    venuebundleList: [],
    prevEmails: [],
    assets: {},
    toggleForAssetChange: false,
    selectedPackage: {},
    isLoading: false,
    isReLoadingBundle: false,
    userSmsTemplates: [],
    venueSmsTemplates: [],
    eventtemplates: [],
    isFetchingLogs: false,
    isClosingLead: false,
    isShowCloseWonBell: false,
    location: [],
    cities: [],
    bundleId: '',
    isFindingNewAgent: false,
    isNewAgentFoundProcessed: false,
    isUpdatingEventInfo: false,
    isInformingVenue: false,
    isSendingUserEmail: false,
    isEditingVisit: false,
    isSchedulingVisit: false,
    selectedVisit: {},
    scheduleVisitId: '',
    isSavingVisit: false,
    selectedMenus: [],
    isEditingPackage: false,
    isViewingPackageMenu: false,
    isSavingPackage: false,
    newFoundAgent: {},
    options: [],
    isSendingSmsToVenue: false,
    isSendingSmsToUser: false,
    selectedVenueID: '',
    eventNoteMap: {},
    eventNoteList: [],
    totalEventNoteCount: 0,
    isCreatingNote: false,
    isUpdatingNote: false,
    isViewingPackage:false,
    selectedFrom:"",
    eventCallLogs:[]
};

const EventReducer = (state = initialState, action) => {
    let cities = [];
    let eventnotes = [];
    let eventNoteMap = {};

    switch (action.type) {
        case SET_EVENT_LOADING_STEP:
            return {
                ...state,
                event: {},
                logs: [],
                tasks: [],
                emailtasks: [],
                smstasks: [],
                recoList: [],
                venueList: [],
                venuebundleList: [],
                prevEmails: [],
                assets: {},
                selectedPackage: {},
                isLoading: true,
                
            };

        case TOGGLE_EVENT_MODAL_FORM:
            return {
                ...state,
                isEventModalVisible: action.isEventModalVisible,
                selectedTab: action.selectedTab,
                selectedFrom:action.selectedFrom
            };

        case SET_EVENT_ID:
            return {
                ...state,
                selectedEventId: action.selectedEventId,
            };

        case FETCH_EVENT_INFO_EVENT_ACTION:
            return {
                ...state,
                event: action.event,
                bundleId: action.bundleId,
            };

        case FETCH_PREVIOUS_EVENTS:
            return {
                ...state,
                previousEvents: action.previousEvents,
            };

        case FETCH_EVENT_BUNDLE:
            let assets = {};
            let venueList = action.bundle && action.bundle.venueList ? action.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
            venueList.forEach(item => {
                assets[item.uid._id] = {};
            });
            return {
                ...state,
                bundle: action.bundle,
                assets: assets,
                toggleForAssetChange: !state.toggleForAssetChange,
                venueList: venueList,
                isLoading: false,
            };

        case UPDATE_FIRST_SEEN_EVENT_STATS:
            return {
                ...state,
                eventstat: action.eventstat,
            };
        case UPDATE_FIRST_SEEN_EVENT_STATS:
            return {
                ...state,
                eventstat: action.eventstat,
            };

        case EVENT_CALL_LOGS :
            return {
                ...state,
                eventCallLogs : action.callLogs

            }    

        case FETCH_CORPORATE_EVENT_DETAILS:
            return {
                ...state,
                corporateEvent: action.corporateEvent,
            };

        case REMOVE_EVENT_INFO:
            return {
                ...state,
                event: action.event,
            };

        case FETCH_BASIC_PARAMETERS:
            let occasions = [];
            let packages = action?.params?.packages ? action.params.packages : [];
            let venuetypes = [];
            if (action.params && action.params.cities && action.params.cities.length > 0) {
                cities = action.params.cities.map((item) => {
                    item.label = item.name;
                    item.value = item.slug;
                    return item;
                });
            }
            if (action.params && action.params.occasions && action.params.occasions.length > 0) {
                occasions = action.params.occasions.map((item) => {
                    item.label = item.name;
                    item.value = item.name;
                    return item;
                });
            }
            if (action.params && action.params.venuetypes && action.params.venuetypes.length > 0) {
                venuetypes = action.params.venuetypes.map((item) => {
                    item.label = item.name;
                    item.value = item.name;
                    return item;
                });
            }
            return {
                ...state,
                cities: cities,
                params: action.params,
                packages: packages,
                occasions: occasions,
                venuetypes: venuetypes
            };

        case FETCH_LOCATION_PARAMETERS:
            return {
                ...state,
                subareas: action.subareas,
                location: action.location
            };

        case FETCH_EVENT_TEMPLATES:
            let eventtemplates = action?.eventtemplates?.length > 0 && action?.eventtemplates.filter(item => item.tag === 'whatsapp-text');
            let userSmsTemplates = action?.eventtemplates?.length > 0 && action?.eventtemplates.filter(item => item.tag === 'user-sms');
            let venueSmsTemplates = action?.eventtemplates?.length > 0 && action?.eventtemplates.filter(item => item.tag === 'venue-sms');
            return {
                ...state,
                userSmsTemplates: userSmsTemplates,
                venueSmsTemplates: venueSmsTemplates,
                eventtemplates: eventtemplates,
            };

        case FETCH_EVENT_STATS:
            return {
                ...state,
                eventStat: action.eventStat,
            };

        case FETCH_FEEDBACK:
            return {
                ...state,
                feedback: action.feedback,
            };

        case FETCH_ASSETS:
            return {
                ...state,
                assets: action.assets,
                toggleForAssetChange: !state.toggleForAssetChange
            };
        case FETCH_VENUE_BUNDLE_LIST:
            return {
                ...state,
                assets: action.assets,
                toggleForAssetChange: !state.toggleForAssetChange,
                venueList: action.venueList,
                recoList: action.recoList,
                prevEmails: action.prevEmails,
            };
        case RELOAD_BUNDLE:
            return {
                ...state,
                venuebundleList: action.venuebundleList,
            };
        case RELOADING_BUNDLE:
            return {
                ...state,
                isReLoadingBundle: action.isReLoadingBundle,
            };
        case FETCH_VENUE_SEGMENT_BY_ID:
            return {
                ...state,
                venuebundleList: action.venuebundleList,
                assets: action.assets,
                toggleForAssetChange: !state.toggleForAssetChange
            };
        case FETCHING_LOGS:
            return {
                ...state,
                isFetchingLogs: action.isFetchingLogs,
            };
        case FETCH_TASKS:
            return {
                ...state,
                tasks: action.tasks,
                task_count: action.task_count,
            };
        case FETCH_EMAIL_TASKS:
            return {
                ...state,
                emailtasks: action.emailtasks,
            };
        case FETCH_SMS_TASKS:
            return {
                ...state,
                smstasks: action.smstasks,
                isFetchingLogs: false
            };
        case FETCH_LOGS:
            return {
                ...state,
                logs: action.logs,
                isFetchingLogs: false
            };

        case CONVERTING_LEAD:
            return {
                ...state,
                event: action.event,
                isShowCloseWonBell: action.isShowCloseWonBell
            };

        case CONVERT_LEAD:
            return {
                ...state,
                isClosingLead: action.isClosingLead,
                isShowCloseWonBell: action.isShowCloseWonBell
            };

        case FETCH_VENUE_LIST:
            return {
                ...state,
                venueList: action.venueList,
            };

        case UPDATING_EVENT_INFO:
            return {
                ...state,
                isUpdatingEventInfo: action.isUpdatingEventInfo,
            };

        case UPDATE_EVENT_INFO:
            return {
                ...state,
                event: action.event,
            };

        case FINDING_AGENT:
            return {
                ...state,
                isFindingNewAgent: action.isFindingNewAgent
            };

        case FIND_AGENT:
            return {
                ...state,
                isFindingNewAgent: action.isFindingNewAgent,
                isNewAgentFoundProcessed: action.isNewAgentFoundProcessed,
                newFoundAgent: action.newFoundAgent
            };

        case SAVING_PACKAGES:
            return {
                ...state,
                asset: action.asset,
                isSavingPackage: action.isSavingPackage,
            };

        case EDIT_PACKAGE:
            return {
                ...state,
                isEditingPackage: action.isEditingPackage,
                isViewingPackageMenu: action.isViewingPackageMenu,
                isViewingPackage: !state.isViewingPackage,
                selectedPackage: action.selectedPackage,
                selectedMenus: action.selectedMenus
            };

        case PLAN_VISIT:
            return {
                ...state,
                assets: action.assets,
                toggleForAssetChange: !state.toggleForAssetChange,
                venueList: action.venueList,
            };

        case UPDATE_VISIT:
            return {
                ...state,
                assets: action.assets,
                toggleForAssetChange: !state.toggleForAssetChange,
                isSavingVisit: action.isSavingVisit,
            };

        case EDIT_VISIT_FORM:
            return {
                ...state,
                isEditingVisit: action.isEditingVisit,
                selectedVisit: action.selectedVisit,
            };

        case SCHEDULE_VISIT:
            return {
                ...state,
                isSchedulingVisit: action.isSchedulingVisit,
                scheduleVisitId: action.scheduleVisitId,
            };

        case SEND_USER_EMAIL:
            return {
                ...state,
                isSendingUserEmail: action.isSendingUserEmail,

            };

        case NOTIFY_VENUES:
            return {
                ...state,
                isInformingVenue: action.isInformingVenue,

            };

        case AGENT_OPTIONS:
            return {
                ...state,
                options: action.options,

            };

        case SMS_TO_USER:
            return {
                ...state,
                isSendingSmsToUser: action.isSendingSmsToUser,

            };

        case SMS_TO_VENUE:
            return {
                ...state,
                isSendingSmsToVenue: action.isSendingSmsToVenue,

            };

        case QUERY_LIST:
            return {
                ...state,
                queryList: action.queryList,

            };

        case QUERY_BUNDLE_SET:
            return {
                ...state,
                queryBundleSet: action.queryBundleSet,

            };
        case FETCH_RECOMMENDED_VENUES:
            return {
                ...state,
                recoListCombined: action.recoListCombined,
            };

        case SELECTED_VENUE:
            return {
                ...state,
                selectedVenueID: action.selectedVenueID,
            };

        case ADDING_CUSTOMER_EVENT_NOTE:
            return {
                ...state,
                isCreatingNote: action.isCreatingNote,
            };

        case ADD_CUSTOMER_EVENT_NOTE_LIST:
            eventNoteMap = state.eventNoteMap;
            if (eventNoteMap[action.event_id]) {
                eventNoteMap[action.event_id].eventNoteList.push(action.eventnote);
                eventNoteMap[action.event_id].noteMap[action.eventnote._id] = 1;
                eventNoteMap[action.event_id].totalEventNoteCount += 1;
            } else {
                eventNoteMap[action.event_id] = {};
                eventNoteMap[action.event_id].eventNoteList = [];
                eventNoteMap[action.event_id].noteMap[action.eventnote._id] = 1;
                eventNoteMap[action.event_id].eventNoteList.push(action.eventnote);
                eventNoteMap[action.event_id].totalEventNoteCount = 1;
            }
            return {
                ...state,
                isCreatingNote: action.isCreatingNote,
                eventNoteMap: eventNoteMap
            };

        case REMOVE_CUSTOMER_EVENT_NOTE_LIST:
            eventNoteMap = state.eventNoteMap;
            if (eventNoteMap[action.event_id]) {
                eventNoteMap[action.event_id].eventNoteList = eventNoteMap[action.event_id].eventNoteList.filter(item => item._id !== action.note_id);
                eventNoteMap[action.event_id].totalEventNoteCount -= 1;
            }
            return {
                ...state,
                isRemovingNote: action.isRemovingNote,
                eventNoteMap: eventNoteMap
            };

        case REMOVING_CUSTOMER_EVENT_NOTE_LIST:
            return {
                ...state,
                isRemovingNote: action.isRemovingNote,
            };

        case UPDATE_CUSTOMER_EVENT_NOTE_LIST:
            eventNoteMap = state.eventNoteMap;
            if (eventNoteMap[action.event_id]) {
                eventNoteMap[action.event_id].eventNoteList = eventNoteMap[action.event_id].eventNoteList.map(item => {
                    if (item._id === action.eventnote._id) {
                        return action.eventnote;
                    }
                    return item;
                });
            }
            return {
                ...state,
                isUpdatingNote: action.isUpdatingNote,
                eventNoteMap: eventNoteMap
            };

        case UPDATING_CUSTOMER_EVENT_NOTE_LIST:
            return {
                ...state,
                isUpdatingNote: action.isUpdatingNote,
            };

        case LOAD_CUSTOMER_EVENT_NOTE_LIST:
            eventNoteMap = state.eventNoteMap;
            console.log("eventNoteMap", action);
            if (action.event_id) {
                if (eventNoteMap[action.event_id]) {
                    action.eventnotes.forEach(item => {
                        if (!eventNoteMap[action.event_id].noteMap[item._id]) {
                            eventNoteMap[action.event_id].noteMap[item._id] = 1;
                            eventNoteMap[action.event_id].eventNoteList.push(item);
                        }
                    })
                    eventNoteMap[action.event_id].totalEventNoteCount = action.totalCount;
                } else {
                    eventNoteMap[action.event_id] = {};
                    eventNoteMap[action.event_id].totalEventNoteCount = action.totalCount;
                    eventNoteMap[action.event_id].eventNoteList = action.eventnotes;
                    eventNoteMap[action.event_id].noteMap = {};
                    action.eventnotes.forEach(item => {
                        eventNoteMap[action.event_id].noteMap[item._id] = 1;
                    })
                }
            }
            console.log("eventNoteMap", eventNoteMap);
            return {
                ...state,
                isUpdatingNote: action.isUpdatingNote,
                eventNoteMap: eventNoteMap,
            };

        default:
            return state;
    }
};

/* Selectors */
export const getQueryList = (state) => state.event.queryList;
export const getQueryBundleSet = (state) => state.event.queryBundleSet;
export const getIsSendingSmsToUser = (state) => state.event.isSendingSmsToUser;
export const getIsSendingSmsToVenue = (state) => state.event.isSendingSmsToVenue;
export const getAgentOptions = (state) => state.event.options;
export const getEventModalState = (state) => state.event.isEventModalVisible;
export const getEventSelectedTab = (state) => state.event.selectedTab;
export const getSelectedEventId = (state) => state.event.selectedEventId;
export const getEvent = (state) => state.event.event;
export const getEventLoadingState = (state) => state.event.isLoading;
// Derivative from event
export const getBundleId = (state) => state.event.bundleId;
export const getLogId = (state) => state.event?.event?.userlog;

export const getBundle = (state) => state.event.bundle;
export const getRecoList = (state) => state.event.recoList;
export const getPrevEmails = (state) => state.event.prevemails;
export const getVenueList = (state) => state.event.venueList;
export const getRecoListCombined = (state) => state.event.recoListCombined;
export const getSelectedVenue = (state) => state.event.selectedVenueID;


export const getAsset = (state) => state.event.assets;
export const getToggleForAssetChange = (state) => state.event.toggleForAssetChange;


export const getPackages = (state) => state.event.packages;
export const getOccasions = (state) => state.event.occasions;
export const getVenuetypes = (state) => state.event.venuetypes;


export const getCorporateEvent = (state) => state.event.corporateEvent ? state.event.corporateEvent : {};
export const getFeedback = (state) => state.event.feedback ? state.event.feedback : {};
export const getPreviousEvents = (state) => state.event.previousEvents ? state.event.previousEvents : [];
export const getEventStat = (state) => state.event.eventStat;
export const getEventtemplates = (state) => state.event.eventtemplates;
export const getUserSmsTemplates = (state) => state.event.userSmsTemplates;
export const getVenueSmsTemplates = (state) => state.event.venueSmsTemplates;

export const getLocation = (state) => state.event.location;


export const getLogs = (state) => state.event.logs;
export const getTasks = (state) => state.event.tasks;
export const getTaskCount = (state) => state.event.task_count;
export const getEmailtasks = (state) => state.event.emailtasks;
export const getSmstasks = (state) => state.event.smstasks;
export const getFetchingLogsStatus = (state) => state.event.isFetchingLogs;
export const getIsShowCloseWonBell = (state) => state.event.isShowCloseWonBell;
export const getIsClosingLead = (state) => state.event.isClosingLead;
export const getReloadingBundleStatus = (state) => state.event.isReLoadingBundle;
export const getCities = (state) => state.event.cities;
export const getVenuebundleList = (state) => state.event.venuebundleList;

export const getNewFoundAgent = (state) => state.event.newFoundAgent;
export const getIsNewAgentFoundProcessed = (state) => state.event.isNewAgentFoundProcessed;
export const getIsFindingNewAgent = (state) => state.event.isFindingNewAgent;
export const getIsUpdatingEventInfo = (state) => state.event.isUpdatingEventInfo;

export const getIsInformingVenue = (state) => state.event.isInformingVenue;
export const getIsSendingUserEmail = (state) => state.event.isSendingUserEmail;
export const getIsEditingVisit = (state) => state.event.isEditingVisit;
export const getIsSchedulingVisit = (state) => state.event.isSchedulingVisit;
export const getSelectedVisit = (state) => state.event.selectedVisit;
export const getScheduleVisitId = (state) => state.event.scheduleVisitId;
export const getIsSavingVisit = (state) => state.event.isSavingVisit;
export const getSelectedMenus = (state) => state.event.selectedMenus;
export const getIsEditingPackage = (state) => state.event.isEditingPackage;
export const getIsViewingPackageMenu = (state) => state.event.isViewingPackageMenu;
export const getSelectedPackage = (state) => state.event.selectedPackage;
export const getIsViewingPackage = (state) => state.event.isViewingPackage;
export const getIsSavingPackage = (state) => state.event.isSavingPackage;
export const getEventCreationState = (state) => state.event.isCreatingNote;
export const getModalSelectedFrom = (state) => state.event.selectedFrom;
export const getEventCallLogs = (state) => state.event.eventCallLogs


export default EventReducer;
