import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
import _ from 'lodash'

const CustomSelect = ({
  values,
  getSelectedValue,
  isCreatable,
  valueKey,
  labelKey,
  isMultiSelect,
  searchAPI,
  placeholder,
  
}) => {
  const [items, setItems] = useState(values||[]);
  const [searchValue,setSearchValue]=useState("")
  const [value,setValue ] = useState([]|| value )


  const addItem = () => {
    setItems([ {[labelKey]:searchValue , [valueKey]:searchValue} || `New item ${items.length + 1}`,...items]);

  };  



  useEffect(()=>{
    let val =[]
  setItems(values)
  for(let item of values){
    val.push(item[labelKey])
  }
  setValue(val)
  },[values])

   const handleSearch = async (newValue) => {
    setSearchValue(newValue)
    let res =await searchAPI(newValue)
    setItems(res)
  };

  const handleSelectChange = (value) => {

    setValue(value)
    if (getSelectedValue) {
      getSelectedValue(value);
    }
  };

  return (
    <Select
      style={{
        width: 300,
      }}
      value={value}
      placeholder={placeholder || 'Custom Dropdown'}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          {isCreatable && (
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              {searchValue}
              <Button type="text" icon={<PlusOutlined />} disabled={searchValue==""} onClick={addItem}>
                Add item
              </Button>
            </Space>
          )}
        </>
      )}
      options={items.map((item) => ({
        label: item[labelKey || 'name'],
        value: item[valueKey || 'name'],
      }))}
      mode={isMultiSelect ? 'multiple' : undefined}
      onChange={handleSelectChange}
      filterOption={false} 
      showSearch={Boolean(searchAPI)}
      onSearch={handleSearch} 
    />
  );
};

export default CustomSelect;
