import fetch from 'isomorphic-fetch';
import moment from 'moment';

const API_URL = process.env.REACT_APP_VENUE_API_URL || `https://venue-api.vmnk.in`;
// const API_URL = `http://localhost:8000`;

export default function callVenueApi(endpoint, method = 'get', body) {

    let token =window.sessionStorage.getItem('token') ? window.sessionStorage.getItem('token') : '';
    let xToken = window.localStorage.getItem("x-token") || ''

    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    if(xToken && xToken!==''){
        headers["x-token"] = xToken 
    }
    
    return fetch(`${API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}
