import callApi, {callApiAndCache} from "../../util/apiCaller";
import async from "async";
import moment from 'moment';
import callEventApi from "../../util/eventApiCaller";
import _ from "lodash";
import dayjs from "dayjs";

export const UPDATING_TASKS_INTO_LIST = "UPDATING_TASKS_INTO_LIST";
export const UPDATE_TOTAL_COUNT = "UPDATE_TOTAL_COUNT";
export const APPEND_TASK_DATA = "APPEND_TASK_DATA";
export const UPDATE_FILTER_WISE_COUNT = "UPDATE_FILTER_WISE_COUNT"
export const UPDATE_TASKS_INTO_LIST = "UPDATE_TASKS_INTO_LIST";
export const FETCH_AGENT_LIST = "FETCH_AGENT_LIST";
export const FETCH_FUNNEL_DATA = "FETCH_FUNNEL_DATA";
export const FETCH_TASK_CONTENT = "FETCH_TASK_CONTENT";
export const FETCH_TASK_STATS = "FETCH_TASK_STATS";
export const FETCH_CURRENT_TASK = "FETCH_CURRENT_TASK";
export const FETCH_BASIC_PARAMETERS = "FETCH_BASIC_PARAMETERS";
export const FETCH_LOCATION_PARAMETERS = "FETCH_LOCATION_PARAMETERS";
export const NEW_EVENT_CREATED = "NEW_EVENT_CREATED";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_CUSTOMER_EVENT_INFO = "UPDATE_CUSTOMER_EVENT_INFO";
export const FETCH_EVENT_INFO = "FETCH_EVENT_INFO";
export const LOAD_CUSTOMER_EVENT_NOTE_LIST = "LOAD_CUSTOMER_EVENT_NOTE_LIST";
export const ADD_CUSTOMER_EVENT_NOTE_LIST = "ADD_CUSTOMER_EVENT_NOTE_LIST";
export const REMOVE_CUSTOMER_EVENT_NOTE_LIST = "REMOVE_CUSTOMER_EVENT_NOTE_LIST";
export const UPDATE_CUSTOMER_EVENT_NOTE_LIST = "UPDATE_CUSTOMER_EVENT_NOTE_LIST";
export const FETCH_BOOKMARKED_LEADS = "FETCH_BOOKMARKED_LEADS";
export const UPDATE_EVENT_STATUS_POST_BOOKMARK = "UPDATE_EVENT_STATUS_POST_BOOKMARK";
export const BOOKMARK_LEAD = "BOOKMARK_LEAD";
export const UPDATE_TOTAL_COUNT_WITHOUT_FILTERS = "UPDATE_TOTAL_COUNT_WITHOUT_FILTERS"
export const HANDLE_VISIT_MODAL_STATE = "HANDLE_VISIT_MODAL_STATE"


export function setPlanVisitModalState (state){
    return (dispatch)=>{
        dispatch({
            type:HANDLE_VISIT_MODAL_STATE,
            isVisitModalOpen : state
        })
    }
}
export function fetchSalesAgentQueueData(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            isFetching: true
        });
        return callApi(
            `v3/admin-dashboard/agent/${agentId}/sales-task-list`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.tasks,
                    taskCount: res.data.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchSalesAgentQueueDataV2Count(agentId, filters ,type) {
    return(dispatch) => {
        return callEventApi(`v3/admin-dashboard/agent/${agentId}/leads-list-v7/count`,
            "POST",
            filters).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: type || UPDATE_TOTAL_COUNT,
                    count: res.data.count
                })
            }
        })
    }
}

export const handleChangeActiveViewToFilters = (view) => {
    if((!_.isEmpty(view))) {
        let tempView = {
            start_date: view.start_date ? dayjs(view.start_date).startOf('day').toISOString() : dayjs().subtract(view.min_days ? view.min_days : 0, 'day').startOf('day').toISOString(),
            end_date: view.end_date ? dayjs(view.end_date).endOf('day').toISOString() : dayjs().add(view.max_days ? view.max_days : 0, 'day').endOf('day').toISOString(),
            date_type: view.date_type
        };

        if(!_.isEmpty(view.overall_status) && view.overall_status !== ""){
            tempView.status = view.overall_status
        }
        if(!_.isEmpty(view.agent_status) && view.agent_status !== ""){
            tempView.agent_status = view.agent_status
        }
        if(!_.isEmpty(view.call_status) && view.call_status !== ""){
            tempView.agent_call_status = view.call_status
        }
        if(!_.isEmpty(view.visit_status) && view.visit_status !== ""){
            tempView.agent_visit_status = view.visit_status
        }

        tempView.pageNum = 1
        tempView.pageSize = 5000

        tempView.isMoreFiltersAllowed = true;

        return tempView
    } else {
        return {}
    }
}

export function fetSalesAgentQueueDataFilterWiseCount(agentId, filters) {
    return (dispatch) => {
        filters.forEach(filter => {
            let convertedFilter = handleChangeActiveViewToFilters(filter)
            return callEventApi(
                `v3/admin-dashboard/agent/${agentId}/leads-list-v7/count`,
                "POST",
                convertedFilter
            ).then((res) => {
                if (res.status === "Success") {
                    dispatch({
                        type: UPDATE_FILTER_WISE_COUNT,
                        filter: filter,
                        count: res.data.count
                    });
                }
            });
        })
    };
}


export function fetchSalesAgentQueueDataV2(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            tasks: [],
            taskCount: 0,
            isFetching: true
        });
        return callEventApi(
            `v3/admin-dashboard/agent/${agentId}/leads-list-v5`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.events,
                    taskCount: res.data?.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchAndAppendTaskData(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: APPEND_TASK_DATA,
            tasks: [],
            taskCount: 0,
            isFetching: true
        });
        return callEventApi(
            `v3/admin-dashboard/agent/${agentId}/leads-list-v7`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch(fetchSalesAgentQueueDataV2Count(agentId, filters))
                dispatch({
                    type: APPEND_TASK_DATA,
                    tasks: res.data.events,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: APPEND_TASK_DATA,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchSalesAgentQueueDataV3(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            tasks: [],
            taskCount: 0,
            isFetching: true
        });
        return callEventApi(
            `v3/admin-dashboard/agent/${agentId}/leads-list-v7`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch(fetchSalesAgentQueueDataV2Count(agentId, filters))
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.events,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

// v2 api todo sudha to replace
export function fetchSalesAgentCallingLogicData(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            isFetching: true
        });
        return callApi(
            `v3/admin-dashboard/agent/${agentId}/v2-sales-task-list`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.tasks,
                    taskCount: res.data.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchFunnelData(name) {
    return (dispatch) => {
        callEventApi(`v3/funnels/funnel/by-name`, "POST", {name: name}).then((res) => {
                if (res.status === "Success") {
                    dispatch({
                        type: FETCH_FUNNEL_DATA,
                        funnel_data: res.data,
                    });
                }
                ;
            }
        );
    }
}

function fetchContentByCode(code, next) {
    callApiAndCache(`v2/contents/code/${code}`, "GET")
        .then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res.data);
            }
        })
        .catch((err) => {
            return next(err);
        });
}

export function fetchTaskStaticContent(id) {
    return (dispatch) => {
        let model = {};
        async.series(
            [
                (cb) => {
                    fetchContentByCode("sales-task-list", (err, data) => {
                        if (err) {
                            return cb();
                        } else {
                            console.log(data);
                            model.salestask = data.content.params.map((item) => item.label);
                            return cb();
                        }
                    });
                },
                (cb) => {
                    fetchContentByCode("closed-lost-reasons", (err, data) => {
                        if (err) {
                            console.log("error", err);
                            return cb();
                        } else {
                            console.log("closed-lost-reasons", data.content.params);
                            model.closedLostReasons = data.content.params;
                            return cb();
                        }
                    });
                },
                (cb) => {
                    fetchContentByCode("calling-status-list", (err, data) => {
                        if (err) {
                            return cb();
                        } else {
                            model.callingStatusList = data.content.params;
                            return cb();
                        }
                    });
                },
            ],
            (err) => {
                if (err) {
                    console.log("err" + err);
                } else {
                    dispatch({
                        type: FETCH_TASK_CONTENT,
                        taskContent: model,
                    });
                }
            }
        );
    };
}

export function fetchTaskStats(id) {
    return (dispatch) => {
        let model = {};
        async.series(
            [
                (cb) => {
                    fetchSalesAgentScores(id, (err, data) => {
                        if (err) {
                            return cb(err);
                        } else {
                            if (data && data.scores) {
                                model.scores = data.scores;
                                return cb();
                            } else {
                                return cb();
                            }
                        }
                    });
                },
                (cb) => {
                    handleFetchCallStats(id, (err, data) => {
                        if (err) {
                            model.agentCallData = {};
                            return cb();
                        } else {
                            model.agentCallData = data;
                            return cb();
                        }
                    });
                },
                (cb) => {
                    handleFetchAgentStats(id, (err, data) => {
                        if (err) {
                            model.agentStats = {};
                            return cb();
                        } else {
                            model.agentStats = data.agentStats;
                            return cb();
                        }
                    });
                },
            ],
            (err) => {
                if (err) {
                    console.log("err" + err);
                } else {
                    dispatch({
                        type: FETCH_TASK_STATS,
                        taskStats: model,
                    });
                }
            }
        );
    };
};

function handleFetchCallStats(id, next) {
    if (id) {
      
        callEventApi(`v2/agentcalls/get-agent-calls-information`, "post", {
            agentId:id
        }).then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res);
            }
        });
    }
}

function handleFetchAgentStats(id, next) {
    if (id) {
        callApi(
            `v3/admin-dashboard/agent/${id}/sales-funnel-agent-stats`,
            "post",
            {}
        ).then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res);
            }
        });
    }
};

function fetchSalesAgentScores(agentId, next) {
    callEventApi(`v3/admin-dashboard/agent/${agentId}/sales-funnel-task-count`, 'GET')
        .then(res => {
            if (res.status === 'Success') {
                return next(null, res.data);
            } else {
                return next(res.data);
            }
        }).catch(err => {
        return next(err);
    });
};

export function fetchCurrentTask(agentId, filters) {
    return dispatch => {
        return callEventApi(`v3/admin-dashboard/agent/${agentId}/sales-task-list`, 'POST', filters)
            .then(res => {
                if (res.status === 'Success') {
                    dispatch({
                        type: FETCH_CURRENT_TASK,
                        current_task: res.data,
                    });
                }
            })
    }
};

export function fetchBasicParameters(city) {
    return (dispatch) => {
        return callEventApi(`v3/dashboard/user/${city}/base-filters`, 'GET').then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_BASIC_PARAMETERS,
                    baseParams: res.data,
                });
            }
        });
    }
}

export function fetchLocationParameters(city) {
    return (dispatch) => {
        callEventApi(`v3/dashboard/user/${city}/subareas`, 'GET').then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_LOCATION_PARAMETERS,
                    subAreas: res.data.subareas,
                });
            }
        });
    };
};

export function createNewEvent(event) {
    return (dispatch) => {
        return callEventApi(`v2/events/plan`, 'post', event).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: NEW_EVENT_CREATED,
                    newEvent: true,
                });
            } else {
                dispatch({
                    type: NEW_EVENT_CREATED,
                    newEvent: false,
                });
            }
        });
    };
}

export function updateEvent(eventId, event, items) {
    return (dispatch) => {
        return callEventApi(`v3/admin-dashboard/event/${eventId}/event-update`, 'POST', {
            event: event,
        }).then(res => {
            if (res.status === 'Success') {
                if (items) {
                    let tasks = items?.map(item => {
                        if (item._id === eventId) {
                            return res.data.event;
                        } else {
                            return item;
                        }
                    });
                    dispatch({
                        type: UPDATE_EVENT,
                        items: tasks,
                    });
                }
            }
        })
    };
};

export function updateCustomerEventInfo(event, _id, stateEvent) {
    return (dispatch) => {
        return callEventApi(`v3/admin-dashboard/customer-event/${_id}/event-update`, 'POST', {
            event: event
        }).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: UPDATE_CUSTOMER_EVENT_INFO,
                    event: res.data.event ? res.data.event : stateEvent,
                });
            }
        });
    };
};

export function fetchEventInfo(id) {
    return (dispatch) => {
        let model = {};
        model.eventId = id;
        callEventApi(`v3/admin-dashboard/event/${id}`, "GET").then((res) => {
            if (res.status === "Success") {
                model.event = res.data.event;
                dispatch({
                    type: FETCH_EVENT_INFO,
                    currentTaskEvent: model.event,
                });
            }
        });
    };
};

export function fetchBookmarkedLeads(agent_id,filters) {
    return (dispatch) => {
        let model = {};
        callEventApi(`v3/admin-dashboard/event/agent/${agent_id}/marked-important-leads`, "Post",filters).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: FETCH_BOOKMARKED_LEADS,
                    bookmarkedList: res.data.list
                });
            }
        });
    };
}

export function updateBookMarkStatus(event_id, event) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_EVENT_STATUS_POST_BOOKMARK,
            event,
            event_id
        })
    };
}

export function bookMarkLead(event_id, agent_id, markImportant) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/event/${event_id}/agent/${agent_id}/mark-important`, "post", {
            markImportant
        }).then((res) => {
            if (res.status === "Success") {
                dispatch(updateBookMarkStatus(event_id, res.data.event));
                // dispatch(fetchBookmarkedLeads(agent_id));
                // let filters = {
                //     "pageNum": 1,
                //     "pageSize": 50,
                //     "isMoreFiltersAllowed": true,
                //     "isSearching": false,
                //     "isVisitAgent": false
                // };
                // dispatch(fetchSalesAgentQueueDataV2(agent_id, filters));
            }
        });
    };
}
