import React, { Component } from "react";
import _ from "lodash";
import { Button, ButtonGroup } from "react-bootstrap";
import shortid from "shortid";
import { connect } from "react-redux";
import moment from "moment";
import {
  getAsset,
  getEvent,
  getIsSendingUserEmail,
  getSelectedVenue,
  getToggleForAssetChange,
  getVenueList,
} from "../../../redux/admin/EventReducer";
import { getTotalAgentMap } from "../../../redux/admin/UserReducer";
import { getAgentName } from "../../../util/utility";
import { DatePicker } from "antd";
import Select from "react-select";

import dayjs from "dayjs";
import { getVisitStatus } from "../../../util/visitStatusUtil";
import {
  markVisitComplete,
  sendVisitLinkToCustomer,
} from "../../../redux/admin/EventActions";
import { CopyOutlined } from "@ant-design/icons";
import { notify } from "react-notify-toast";

const TASK_MODAL_VIEW = "taskModal";

class BookedVisitsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venues: [],
      visits: [],
      visit_agent_name: "",
      isDateEditVisible: false,
      isAgentEditVisible: false,
    };
  }

  componentDidMount() {
    let venues = [];
    // this.props.venueList.forEach(item => {
    //     let venue = {};
    //     venue.venueId = item.uid._id;
    //     venue.selected = true;
    //     this.props.assets[item.uid._id].visits.forEach(visit => {
    //         venue.packageId = visit._id;
    //         venues.push(venue);
    //     });
    // });

    this.setState({
      venues: venues,
    });
    this.getVisitAgentOptionsList(this.props.totalAgentMap, this.props.events);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.events !== this.props.events) {
      this.getVisitAgentOptionsList(
        this.props.totalAgentMap,
        this.props.events
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venues) {
      let venues = [];
      this.setState({
        venues: venues,
      });
    }
  }

  onDateChange = (id, venueId, dateString) => {
    if (dateString == "") return;
    let visit = {};
    visit.visit_date = dayjs(dateString).toISOString();

    this.props.updateVisit(venueId, id, visit);
  };

  handleSelectionChange = (venueId, subId, val) => {
    this.props.updateAssetData(
      venueId,
      "visits",
      subId,
      "isPackageSelected",
      val
    );
  };

  sendEmail = () => {
    let venueList = [];
    this.props.venueList
      .filter((data) => data._id == this.props.selectedVenueID)
      .forEach((venue) => {
        if (
          !_.isEmpty(this.props.assets) &&
          !_.isEmpty(this.props.assets[venue.uid._id])
        ) {
          this.props.assets[venue.uid._id].visits
            .filter((item) => item.isPackageSelected)
            .map((item) => {
              venueList.push({
                venueId: venue.uid._id,
                visitId: item._id,
              });
            });
        }
      });
    this.props.send(venueList);
  };

  toggleControl = (venueId, subId, keyName, val) => {
    let visit = {};
    visit[keyName] = !val;
    this.props.updateVisit(venueId, subId, visit);
  };

  markVisitComplete = (venueId, visit_id, isVisitCompleted) => {
    if (isVisitCompleted) {
      return;
    } else {
      const confirmation = window.confirm(
        "Are you sure you want to mark this visit as complete? This action cannot be reverted."
      );

      if (confirmation) {
        this.props.dispatch(markVisitComplete(venueId, visit_id));
      } else {
      }
    }
  };

  getVisitAgentOptionsList = (totalAgentMap, event) => {
    let visitAgentsOptions = [];
    if (
      this.props.event &&
      this.props.event.visit_agents &&
      this.props.event.visit_agents.length > 0
    ) {
      visitAgentsOptions = this.props.event.visit_agents.map((id) => {
        return {
          label: totalAgentMap[id]?.name,
          value: id,
        };
      });
    }
    if (this.props.event && this.props.event.agent_id) {
      visitAgentsOptions.push({
        label: this.props.event.agent_name,
        value: this.props.event.agent_id,
      });
    }
    this.setState({
      visitAgentsOptions: visitAgentsOptions,
    });
  };

  handleOnAgentChange = (visitid, venueId, value) => {
    let visit = {};
    visit.agentId = value.value;
    this.props.updateVisit(venueId, visitid, visit);
  };

  handleCopyLink = (review_link) => {
    let link = `${review_link}?mgk=${this.props?.event?.magicToken}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notify.show("Link copied to clipboard", "success", 3000);
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
        notify.show("Failed to copy link to clipboard");
      });
  };

  getVenueAssetsMap = () => {
    if (this.props.viewMode === TASK_MODAL_VIEW) {
      return this.props.venueList && this.props.venueList;
    }
    return (
      this.props.venueList &&
      this.props.venueList.filter(
        (data) => data._id == this.props.selectedVenueID
      )
    );
  };

  render() {
    return (
      <div
        id="useremail-packages"
        className="panel panel-default"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        {this.props.viewMode === TASK_MODAL_VIEW ? null : (
          <div className="panel-heading">Planned Visits</div>
        )}
        <table className="table">
          <thead>
            <tr>
              {!TASK_MODAL_VIEW && <th>Select</th>}
              {/* <th>Venue Details</th> */}
              {!TASK_MODAL_VIEW && <th>Visible To User</th>}
              <th>Date</th>
              <th>Confirmation</th>
              <th>Visit Agent</th>
              <th>Status</th>
              <th>Visit Done</th>
              <th>Copy Link</th>
              <th>Send Link</th>
              <th>Cancel</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {this.props.venueList && this.props.venueList.length < 1 ? (
              <tr>
                <td>No Venues</td>
              </tr>
            ) : (
              <tr></tr>
            )}
            {this.getVenueAssetsMap().map((item) =>
              this.props.assets[item.uid._id] &&
              this.props.assets[item.uid._id].visits &&
              this.props.assets[item.uid._id].visits.length > 0
                ? this.props.assets[item.uid._id].visits.map((visit) => (
                    <tr
                      className="mngevent-email-details"
                      key={shortid.generate()}
                    >
                      {!TASK_MODAL_VIEW && (
                        <td>
                          <Button
                            onClick={() =>
                              this.handleSelectionChange(
                                item.uid._id,
                                visit._id,
                                !visit.isPackageSelected
                              )
                            }
                            className={"btn-default"}
                          >
                            {visit.isPackageSelected ? (
                              <i className="fa fa-check" />
                            ) : (
                              <i className="fa fa-times" />
                            )}
                          </Button>
                        </td>
                      )}
                      {!TASK_MODAL_VIEW && (
                        <td>
                          <Button
                            onClick={() =>
                              this.toggleControl(
                                item.uid._id,
                                visit._id,
                                "isVisibleToUser",
                                !!visit.isVisibleToUser
                              )
                            }
                          >
                            {visit.isVisibleToUser ? "Yes" : "No"}
                          </Button>
                        </td>
                      )}

                      <td>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {TASK_MODAL_VIEW && item.venue_name}

                          <DatePicker
                            style={{ width: "180px" }}
                            showTime
                            value={dayjs(visit.visit_date)}
                            onChange={(value, dateString) =>
                              this.onDateChange(
                                visit._id,
                                visit.venueId,
                                dateString
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <span>User</span>
                        <span style={{ marginLeft: "5px" }}>
                          <Button
                            className="btn btn-sm btn-default"
                            onClick={() =>
                              this.toggleControl(
                                item.uid._id,
                                visit._id,
                                "isConfirmedByUser",
                                !!visit.isConfirmedByUser
                              )
                            }
                          >
                            {visit.isConfirmedByUser ? "Yes" : "No"}
                          </Button>
                        </span>
                        <br />
                        <span>Venue</span>
                        <span style={{ marginLeft: "5px" }}>
                          <Button
                            className="btn btn-sm btn-default"
                            onClick={() =>
                              this.toggleControl(
                                item.uid._id,
                                visit._id,
                                "isConfirmedByVenue",
                                !!visit.isConfirmedByVenue
                              )
                            }
                          >
                            {visit.isConfirmedByVenue ? "Yes" : "No"}
                          </Button>
                        </span>
                      </td>

                      <td>
                        <Select
                          style={{ width: "140px" }}
                          name="Agents"
                          placeholder="Agents"
                          options={this.state.visitAgentsOptions}
                          // multi={true}
                          openOnFocus={true}
                          value={visit.agentId}
                          onChange={(value) =>
                            this.handleOnAgentChange(
                              visit._id,
                              item.uid._id,
                              value
                            )
                          }
                          clearable={false}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999, // Change this value to adjust z-index
                            }),
                          }}
                        />
                      </td>

                      <td>{getVisitStatus(visit)}</td>
                      <td>
                        <Button
                          onClick={() =>
                            this.markVisitComplete(
                              item.uid._id,
                              visit._id,
                              visit.isVisitCompleted
                            )
                          }
                        >
                          {visit.isVisitCompleted ? "yes" : "No"}
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            this.handleCopyLink(visit.venue_review_link);
                          }}
                        >
                          <CopyOutlined />
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            this.props.dispatch(
                              sendVisitLinkToCustomer(visit._id)
                            );
                          }}
                        >
                          Send
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            this.toggleControl(
                              item.uid._id,
                              visit._id,
                              "isVisitCancelled",
                              !!visit.isVisitCancelled
                            )
                          }
                        >
                          {visit.isVisitCancelled ? "Yes" : "No"}
                        </Button>
                      </td>
                      <td>
                        <Button
                          disabled={item.isDeletingVisit}
                          onClick={() =>
                            this.props.removeVisit(item.uid._id, visit._id)
                          }
                        >
                          {item.isDeletingVisit ? (
                            <i className="fa fa-refresh fa-spinner" />
                          ) : (
                            <i className="fa fa-trash-o" />
                          )}
                        </Button>
                      </td>
                    </tr>
                  ))
                : null
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    assets: getAsset(state),
    toggleForAssetChange: getToggleForAssetChange(state),
    isSendingUserEmail: getIsSendingUserEmail(state),
    venueList: getVenueList(state),
    selectedVenueID: getSelectedVenue(state),
    totalAgentMap: getTotalAgentMap(state),
    event: getEvent(state),
  };
}

export default connect(mapStateToProps)(BookedVisitsInfo);
