import React, { useState } from "react";
import { Modal, Button } from "antd";
import { PlayCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import callEventApi from "../../../../util/eventApiCaller";
import { useDispatch } from "react-redux";
import { reprocessCallLogsWithCallId } from "../../../../redux/admin/EventActions";

export default function EventCallLogsRow({ log ,index , isEnquiry ,handleFetchDetails}) {
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleReprocess = () => {
    dispatch(reprocessCallLogsWithCallId(log._id,! isEnquiry ? log.eventId : undefined ,handleFetchDetails))
  };
  return (
    <React.Fragment>
      <tr className="prev-details" key={log._id}>
        <td>{index+1}</td>
        <td>{isEnquiry ? log.enquiry_num : log.agent_name}</td>
        <td style={{ width: "160px", display: "block" }}>
          {log.call_start_time
            ? dayjs(log.call_start_time).format("DD-MM-YYYY hh:mm:ss")
            : ""}
        </td>
        <td>{log.call_type}</td>
        <td>--</td>
        <td style={{ width: "160px", display: "block" }}>
          {log.call_end_time
            ? dayjs(log.call_end_time).format("DD-MM-YYYY hh:mm:ss").toString()
            : ""}
        </td>
        <td>{log.call_duration ? `${log.call_duration} sec` : ""}</td>
        <td>{log.agent_call_status}</td>
        <td>{log.customer_call_status}</td>
        <td>
          <div style={{display:"flex"}}>
          <button onClick={handleModalOpen} className="play-button">
            <PlayCircleOutlined />
          </button>
          <button onClick={handleReprocess} className="reprocess-button">
            <ReloadOutlined />
          </button>
          </div>
        </td>
      </tr>
      {modalVisible &&
      <Modal
        title="Audio"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <audio controls>
          <source src={log.document_link} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Modal>}
    </React.Fragment>
  );
}
