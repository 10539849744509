export const GLOBAL_DELETE_AGENT = 'GLOBAL_DELETE_AGENT';
export const NEW_EVENT = 'NEW_EVENT';
export const REQUEST_TO_CANCEL_BY_CUSTOMER = 'REQUEST_TO_CANCEL_BY_CUSTOMER';
export const REQUEST_TO_CANCEL_BY_AGENT = 'REQUEST_TO_CANCEL_BY_AGENT';
export const REQUEST_TO_CLOSE_LEAD_BY_AGENT = 'REQUEST_TO_CLOSE_LEAD_BY_AGENT';
export const REQUEST_TO_CANCEL_BY_VENUE = 'REQUEST_TO_CANCEL_BY_VENUE';
export const SHORTLIST_VENUE_BY_CUSTOMER = 'SHORTLIST_VENUE_BY_CUSTOMER';
export const IS_USER_ONLINE_OFFLINE = 'IS_USER_ONLINE_OFFLINE';
export const SHORTLIST_VENUE_BY_AGENT = 'SHORTLIST_VENUE_BY_AGENT';
export const PLAN_VISIT_BY_AGENT = 'PLAN_VISIT_BY_AGENT';
export const PLAN_VISIT_BY_CUSTOMER = 'PLAN_VISIT_BY_CUSTOMER';
export const REQUEST_BOOKING_BY_CUSTOMER = 'REQUEST_BOOKING_BY_CUSTOMER';
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';
export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS';
export const CHANGE_AGENT_FOR_EVENT='CHANGE_AGENT_FOR_EVENT';
export const UPDATE_AGENT_STATE='UPDATE_AGENT_STATE'
export const REQUEST_BOOKING_BY_AGENT = 'REQUEST_BOOKING_BY_AGENT';
export const AGENT_LOGOUT = 'AGENT_LOGOUT';
export const SOCKET_IO = 'socket-io';
export const PUBNUB = 'pubnub';