import React, {Component} from "react"
import './AgentSidebar.css'
import {connect} from "react-redux";
import _ from 'lodash';
// import EventModalForm from './../EventModalForm/EventModalForm'
import {closeAgentButton, openAgentButton, agentCredentialsApiCall} from "./../../redux/admin/OnlineCustomerActions";
import {
    getOpenAgentList,
    getShowFabButton,
    getOnlineUsers,
    getPubnubCredentials,
    getOnlineUserList,
    getTotalOnlineUser, getOnlineUserMap,
} from './../../redux/admin/OnlineCustomerReducer';
import {setSelectedEventId, toggleEventModalForm} from "../../redux/admin/EventActions";
import {getEventModalState, getEventSelectedTab} from "../../redux/admin/EventReducer";
import {getAgentTasks} from "../../redux/admin/SalesAgentsQueueReducer"
import {Button, ButtonGroup} from "react-bootstrap";
import {fetchTaskStaticContent} from "../../redux/admin/SalesAgentQueueActions";
import { setAvailabilityCalendarVisibility } from "../../redux/admin/AvailaibilityCalendarActions";

class AgentSidebar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(fetchTaskStaticContent(this.props.agentid)); // async
        if (this.props.onlineUsers && this.props.onlineUsers.length > 0) {
            this.props.dispatch(agentCredentialsApiCall(this.props.onlineUsers, this.props.agentid));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.onlineUsers?.length !== prevProps.onlineUsers?.length) {
            this.props.dispatch(agentCredentialsApiCall(this.props.onlineUsers, this.props.agentid));
        }
        if (this.props.agentid !== prevProps.agentid) {
            this.props.dispatch(fetchTaskStaticContent(this.props.agentid)); // async
        }
    }

    openAgent = () => {
        this.props.dispatch(agentCredentialsApiCall(this.props.onlineUsers, this.props.agentid));
        this.props.dispatch(openAgentButton())
    }

    closeAgent = () => {
        this.props.dispatch(closeAgentButton())
    }

    openEventModal = (tab) => {
        this.props.dispatch(toggleEventModalForm(true, tab));
    };

    closeEventModal = () => {
        this.props.dispatch(toggleEventModalForm(false, 1));
    };

    loadEvent = (id) => {
        this.props.dispatch(setSelectedEventId(id));
    };

    openForm = (tab, id) => {
        if (id && id !== "") {
            this.openEventModal(tab);
            this.loadEvent(id);

        }
    }

    filterLeadsByAgent = (agentid, event) => {
        return (agentid && event && (event?.agent_id === agentid || event?.visit_agents.includes(agentid)))
    };

    getLeads = () => {
        return this.props.onlineUserList && this.props.onlineUserList.length > 0 && this.props.onlineUserList?.filter(item => this.filterLeadsByAgent(this.props.agentid, item));
    };

    render() {
        // console.log("this.props.agentSideBar", this.props);
        return (
            <div>
                <div>
                <div className="floating-button">

                                <button 
                                     onClick={()=>this.props.dispatch(setAvailabilityCalendarVisibility(true))}>
                                <i class="fa fa-calendar" aria-hidden="true"/>
                                     </button>
                            </div>
                    {this.props.showButton ?
                        <React.Fragment>

                            <div className="floating-button" style={{bottom:'5%'}}>

                                <button  
                                     onClick={this.openAgent}>{this.getLeads()?.length > 0 ? this.getLeads()?.length : '+'}</button>
                            </div>
                        </React.Fragment> : null}
                    <> {this.props.openAgentList ?
                        <>
                            <div className="container-bottombar">
                                <div className='close-cross'>
                                    <Button onClick={() => this.closeAgent()}><i className="fa fa-close"
                                                                                 area-hidden='true'></i></Button>
                                </div>
                                <div className="media-scroller">
                                    {this.getLeads() && this.getLeads()?.map((item, index) => {
                                            return (
                                                <div key={index} data-toggle="tooltip" data-placement="left"
                                                     title={item?.contact_name || '----'}>
                                                    <Button
                                                        style={{marginTop: '15px', marginRight: '10px'}}
                                                        onClick={() => this.openForm(6, item._id)}
                                                    >
                                                        <i className="fa fa-bolt" style={{marginRight: '2px'}}></i>
                                                        {item?.contact_phone}
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        </> : null}</>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        openAgentList: getOpenAgentList(state),
        showButton: getShowFabButton(state),
        onlineUsers: getOnlineUsers(state),
        onlineUserMap: getOnlineUserMap(state),
        pubnubCredentials: getPubnubCredentials(state),
        onlineUserList: getOnlineUserList(state),
        totalOnlineUser: getTotalOnlineUser(state),
        isEventModalVisible: getEventModalState(state),
        selectedTab: getEventSelectedTab(state),
        tasks: getAgentTasks(state),
    }
}

export default connect(mapStateToProps)(AgentSidebar);

