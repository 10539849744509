import React, {useState} from 'react';
import moment from 'moment';
import utility from '../../../util/utility';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {getTaskCount, getTasks} from "../../../redux/admin/EventReducer";
import {fetchEventTasks} from "../../../redux/admin/EventActions";
import {getTotalAgentMap} from "../../../redux/admin/UserReducer";

function EventTasks({event_id}) {

    const dispatch = useDispatch();

    const [isPendingVisible, toggleIncompleteFilter] = useState(false);

    const tasks = useSelector(state => getTasks(state));
    const task_count = useSelector(state => getTaskCount(state));
    const totalAgentMap = useSelector(state => getTotalAgentMap(state));

    const loadTasks = () => {
        dispatch(fetchEventTasks(event_id));
    }

    let segment = null;
    if (tasks && tasks.length > 0) {
        segment = <div className="row">
            <div className="col-xs-12">
                <div className="panel">
                    <div className="panel-heading">
                        Tasks <button className={'btn-sm btn-warning'} onClick={() => toggleIncompleteFilter(!isPendingVisible)}>
                        {isPendingVisible ? 'Show All' : 'Show Incomplete' }</button>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Task Created Date</th>
                            <th>Task Code</th>
                            <th>Agent Name</th>
                            <th>Status</th>
                            <th>Last Event Status</th>
                            <th>Score</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tasks?.filter(item => isPendingVisible ? !item.completed : true).map((item, i) => (
                            <tr className="mngevent-email-details" key={item._id}>
                                <td>
                                    {moment(new Date(item.created_on)).format('DD MMM YYYY HH:mm a')}
                                </td>
                                <td>
                                    {item.code}
                                    <br/>
                                    {item.completed ?
                                        <a className={'btn-xs btn-danger'}>completed</a>
                                        :
                                        null
                                    }
                                </td>
                                <td>
                                    {utility.getAgentName(totalAgentMap, item.agentid)}
                                </td>
                                <td>
                                    Call Status - {item.agent_call_status} <br/>
                                    Current Event Status - {item.new_event_status} <br/>
                                    Visit Status {item.visit_status_code} <br/>
                                </td>
                                <td>
                                    {item.old_event_status}
                                </td>
                                <td>
                                    {item.score}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    } else {
        segment =
            <div className="panel">
                <div className="panel-heading">
                    Tasks <Button
                    onClick={() => loadTasks()}
                >Load Tasks</Button>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="text-center">

                        </div>
                    </div>
                    <div className="col-xs-12">
                        <div className="text-center">
                            No tasks found.
                        </div>
                    </div>
                </div>
            </div>;
    }
    return segment;
}

export default EventTasks;
