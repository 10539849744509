import React from 'react';

const ImportantInfoTags = ({event_tags,className,styles,sortOrder,maxTags}) => {


  const tagsSortOrder = sortOrder || ['green','red', 'magenta','yellow', 'blue'];


  const colors = {
    blue:"#03a9f4", 
  }


  const getColor = (color)=>{
    let colorCode = colors[color]
    return colorCode || color

  }
  const getTagsToShow = () => {
    if (event_tags && event_tags.length > 0) {

      const sortedTags = event_tags.sort((a, b) => {
        const aIndex = tagsSortOrder.indexOf(a.color);
        const bIndex = tagsSortOrder.indexOf(b.color);
        return aIndex - bIndex;
      });

      let tagsToShow = sortedTags.slice(0,maxTags)
      return tagsToShow.map((tag) => (
        <div className={className}  key={tag._id} style={{...styles, backgroundColor: `${getColor(tag.color)}`,borderColor:`${getColor(tag.color)}` }}>
          {tag.value}
        </div>
      ));
    } else {
      return <></>;
    }
  };

  return <>{getTagsToShow()}</>;
};

export default ImportantInfoTags;
