import {
    Drawer,
    Form,
    Button,
    DatePicker,
    Select,
    Radio,
    Space,
    Spin,
    Tabs
  } from "antd";
  import React, { useEffect } from "react";
  import dayjs from "dayjs";
  import _ from "lodash";
 
  import { useState } from "react";

import {  AVAILABLE,
  BOOKED,
  SLOT_OPTIONS,
  UNAVAILABLE,} from "../../../../../constants/venueCalendarEnums";
import AccessControl from "../../../../PermissionLocks/AccessControl";

  
  export default function UpdateEventForm(props) { 
    
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState(false);
  
    useEffect(() => {
      if (!_.isEmpty(props.event)) {
        form.setFieldsValue({
          date: dayjs(props.event.start).startOf('day'),
          slot:props.eventObjMap?.[props.event.id]?.slot,
          status:props.eventObjMap?.[props.event.id]?.status
        });
      }
    }, [props.event]);

  
    const onClose = () => {
      props.setUpdateDrawerVisible(false);
    };
  
    const onFinish = () => {
      form.validateFields().then(() => {
        setIsUpdating(true);
        let value = form.getFieldsValue();
        let payload = {};
        payload.start_date = value.date.toISOString();
        payload.time_slot = value.slot;
        payload.time_slot_status = value.status;
        payload.venuespace_id = props.eventObjMap?.[props.event.id]?.venueSpaceId ? props.eventObjMap?.[props.event.id]?.venueSpaceId : props.venue._id
        props.update(props.event.id,payload).then(() => {
          setIsUpdating(false);
          onClose();  
        }).catch(()=>{
          setIsUpdating(false)
        });
      });
    };
  
    const dateFieldValidator = () => {
      let date = form.getFieldValue("date");
      if (date) {
        return Promise.resolve();
      } else {
        return Promise.reject("Date start and end is required");
      }
    };
    const handleSoftDelete=()=>{
      setIsUpdating(true);     
        props.update(props.event.id,{is_deleted:true}).then(()=>{
        setIsUpdating((false));
        onClose()   
      })



    }
    const handleHardDelete=()=>{
      setIsUpdating(true);
      props.remove(props.event.id).then(()=>{
        setIsUpdating((false));
        onClose()
      })
     
    }

    const items =[
      {
      key:'edit',
      label:'Edit',
      children: <Form form={form} name="eventForm" layout="vertical">
      <Form.Item
        name="date"
        label={<b>From-To</b>}
        rules={[
          {
            validator: dateFieldValidator,
          },
        ]}
      >
        <DatePicker format={"DD/MM/YYYY"} disabled={true}></DatePicker>
      </Form.Item>
      <Form.Item
        name="slot"
        label={<b>Slot</b>}
        rules={[
          { required: true, message: "Slot needed to be selected" },
        ]}
      >
        <Select size={"large"} disabled={true} style={{ width: "140px" }}>
          {SLOT_OPTIONS.map((item) => (
            <Select.Option value={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="status"
        rules={[{ required: true, message: "Status Cannot be empty" }]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={BOOKED}>{_.startCase(BOOKED)}</Radio>
            <Radio value={AVAILABLE}>{_.startCase(AVAILABLE)}</Radio>
            <Radio value={UNAVAILABLE}>{_.startCase(UNAVAILABLE)}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </Form>
      }
      ,
      {
        key:'delete',
        label:"Delete",
        children:
        <div style={{justifyContent: "space-between",display: "flex"}}>
          <Button type="primary" onClick={handleSoftDelete}>Delete</Button>

        <AccessControl userPermissions={props.userPermissions} shouldHavePermissions={["venuecalendar:delete"]}> <Button type="primary"onClick={handleHardDelete}>Hard delete</Button></AccessControl>
        </div>
      }    
    ]


    return (
      <div>
        <Drawer
          title={<b>{props.event.title}</b>}
          placement="right"
          onClose={() => onClose()}
          open={props.open}
          width={400}
          footer={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="primary" onClick={onClose} disabled={isUpdating}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                disabled={isUpdating}
                onClick={onFinish}
              >
                Ok
              </Button>
            </div>
          }
        >
          <Spin spinning={isUpdating}>
            <Tabs items={items}></Tabs>
           
          </Spin>
        </Drawer>
      </div>
    );
  }
  