import React, {useEffect, useState} from 'react';
import {
    Button
} from 'react-bootstrap';
import callVenueApi from '../../../util/venueAPICaller';
import { notify } from 'react-notify-toast';
import _ from 'lodash'
import dayjs from 'dayjs';
import callApi from '../../../util/managerAPICaller';
import CalendarStatusTable from './CalendarStatusTable';
import { sortByKeyReverse } from '../../../util/utility';
import { giveStatusValue } from '../../../util/venueCalendar';
import CalendarDrawer from './CalendarDrawer';
import showToastMessage from '../../../util/notificationUtil';
import { Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { setAvailabilityCalendarVisibility, setAvailaibilityCalendarSelectedVenue } from '../../../redux/admin/AvailaibilityCalendarActions';


const VENUE_CHUNK_LIMIT = 10
function RecoVenueCombined(props) {
    const [event, setEvent] = useState({});
    const [venueEventObjModal,setVenueEventObjModal] = useState({})
    const [refresh,triggerRefresh]=useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
     
        let event = {...props.event};
       
        if (props.event.budget_end) {
            if (parseInt(props.event.budget_end) <= 800) {
                event.budget_end = 'Under 800';
            } else if (parseInt(props.event.budget_end) > 800 && parseInt(props.event.budget_end) <= 1200) {
                event.budget_end = '800-1200';
            } else if (parseInt(props.event.budget_end) > 1200 && parseInt(props.event.budget_end) <= 1600) {
                event.budget_end = '1200-1600';
            } else if (parseInt(props.event.budget_end) > 1600 && parseInt(props.event.budget_end) <= 1800) {
                event.budget_end = '1600-1800';
            } else if (parseInt(props.event.budget_end) > 1800 && parseInt(props.event.budget_end) <= 2200) {
                event.budget_end = '1800-2200';
            } else if (parseInt(props.event.budget_end) > 2200) {
                event.budget_end = 'Above 2200';
            }
        }
        if (props.event.guests) {
            if (parseInt(props.event.guests) <= 20) {
                event.guests = 'Under 20';
            } else if (parseInt(props.event.guests) > 20 && parseInt(props.event.guests) <= 50) {
                event.guests = '20-50';
            } else if (parseInt(props.event.guests) > 50 && parseInt(props.event.guests) <= 100) {
                event.guests = '50-100';
            } else if (parseInt(props.event.guests) > 100) {
                event.guests = '100+';
            }
        }
        setEvent(event);
    }, [props.event])

    useEffect(() => {
        if (props.event) {
          if (props.event?.event_date && props.venues && !_.isEmpty(props.venues)) {
           let venueIds = getvenueIdsList(props.venues);
           let venueIdsChunk = _.chunk(venueIds, VENUE_CHUNK_LIMIT);
           let filters = {
             start_date: dayjs(props.event.event_date).startOf("day").toISOString(),
             end_date: dayjs(props.event.event_date).endOf("day").toISOString(),
           };
           let promises = venueIdsChunk.map((chunk) => {
             return fetchCalendarEventsForVenues(chunk, filters);
           });
           Promise.all(promises)
             .then((responses) => {
               let allEvents = [].concat(...responses);
               let sortedList = sortEventsList(allEvents);
 
               let venueEventsObj = createVenueEventsObjMap(
                 venueIds,
                 sortedList
               );
 
               setVenueEventObjModal(venueEventsObj);
             })
 
             .catch((error) => {
               console.error(error);
             });
         }
       }
    }, [props.venues,props.event,refresh]);

    
    const sortEventsList=((list)=>{
      let listWithAddOns =list.map((item)=>{
       return {...item,timeStamp:dayjs(item.start_date).unix(),statusIndex:giveStatusValue(item.time_slot_status)}
      })
     let dateSortedList=sortByKeyReverse(listWithAddOns,"timeStamp")
     let statusSortedList=sortByKeyReverse(dateSortedList,"statusIndex")
     return statusSortedList
    
    })

    const createVenueEventsObjMap = (venueIds, eventsList) => {
      let modal = {};
      for (let venueId of venueIds) { 
        modal[venueId] = [];
        for (let event of eventsList) {
          if (venueId == event.venue_id) {
            event.venueSpaceName = event.venuespace_id?.name
            modal[venueId].push(event);
          }
        }
      }
      return modal;
    };


    const getvenueIdsList=(venueList)=>{
    return venueList.map((item)=>{
        return item._id 
    })

    }
    const refreshList=()=>{
      triggerRefresh(refresh+1)
    }



    const fetchCalendarEventsForVenues = async (venues, filters) => {
      if (venues && _.isArray(venues)) {
        let body = {};
        body.venue_ids = venues;
        if (filters) {
          body.filters = filters;
        }
        try {
          let res = await callVenueApi(`v1/venue-calender/event-lists`, "post", body);

          if (res.status == "Success") {
        
            return res.data.venuecalenderList;
          } else {
            return [];
          }
        } catch (e) {
          console.log(e);
        }
      }
      else return[]
    };

    const getIfShortlistedVenue = (venueId) => {
        return !!props.assets[venueId]
    }

    const handleCalendarButtonClicked=(venue)=>{
      dispatch(setAvailaibilityCalendarSelectedVenue(venue))
      dispatch(setAvailabilityCalendarVisibility(true))
    }

    const getVenueSegment = () => {
        let segment = null;
        if (props.venues && props.venues.length > 0) {
            segment = (
              <div
                className="list-group"
                style={{
                  height: "250px",
                  overflowY: "auto",
                  borderTop: "1px solid",
                }}
              >
                {/* {"Venue Space"}{"Availability Date Range"}{"Availability Status"} */}
                {props.venues.map((item) => (
                  <div className="list-group-item" key={item._id}>
                    <div className="row">
                      <div className="col-xs-4">
                        <div className="row">
                          <div className="col-xs-12">
                            <span>{item.name}</span>
                            <br/>
                            <span style={{fontSize: "13px" }}>{item.sub_area}</span>
                          </div>
                          <div className="col-xs-12">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {item.algo ? (
                                <div className="btn btn-sm tags-rec-venues">
                                  {item.algo}
                                </div>
                              ) : null}
                              {item.city ? (
                                <div className="btn btn-sm  tags-rec-venues">
                                  <i
                                    className="fa fa-map-marker space-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.city}
                                </div>
                              ) : null}
                              {item.veg_show ? (
                                <div className="btn btn-sm  tags-rec-venues-veg">
                                  <i
                                    className="fa fa-cutlery space-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.veg_show}
                                </div>
                              ) : null}
                              {item.nonveg_show ? (
                                <div className="btn btn-sm  tags-rec-venues-non-veg">
                                  <i
                                    className="fa fa-cutlery space-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.nonveg_show}
                                </div>
                              ) : null}
                              {item.cap_max ? (
                                <div className="btn btn-sm  tags-rec-venues">
                                  <i
                                    className="fa fa-users space-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.cap_max}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div className='col-xs-6'>
                    
                          {venueEventObjModal[item._id] &&
                          !_.isEmpty(venueEventObjModal[item._id]) && (
                            <CalendarStatusTable
                              CalendarEvent={venueEventObjModal[item._id]}
                              venueName={item.name}
                            />
                          )}
                      </div>

                      <div className="col-xs-2">
                        <div className="row text-left" style={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>

                          {getIfShortlistedVenue(item._id) ? (
                            <>
                            <Button style={{marginRight:"2px"}} disabled={getIfShortlistedVenue(item._id)}>
                              {"Selected"}
                            </Button>
                            <i style={{cursor:"pointer"}} onClick={()=>{handleCalendarButtonClicked(item)}}  class="fa fa-calendar" aria-hidden="true" ></i>
                            </>
                          ) : (
                            <>
                            <Button
                            style={{marginRight:"2px"}}
                              onClick={() =>
                                props.shortlistVenue(props.bundleid, item._id)
                              }
                            >
                              Shortlist
                            </Button>
                            <i style={{cursor:"pointer"}} onClick={()=>{handleCalendarButtonClicked(item)}}  class="fa fa-calendar" aria-hidden="true" ></i>
                            </>
                          )}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                 
                ))}
                 
              </div>
            );
        } else {
            segment = <div className="list-group">
                <div className="list-group-item">
                    <div className="text-center">
                        No Recommended Venues
                    </div>
                </div>
            </div>;
        }
        return segment
    }

    return (
      
        <div className="list-group">
          {props.event?.event_date && !dayjs(props.event.event_date).isValid() && (
          <Alert  message=<div style={{display:"flex" ,justifyContent:"center"}}> Add Event Date To see Availability Status </div> type="warning" />
          )}
            <div className='list-group-item'>
                <div style={{display: 'flex', flexDirection: 'row', minHeight: '15px', margin: '12px'}}>
                    {
                        props.event.category ? <div className='tags'><i className="fa fa-calendar space-right"
                                                                        aria-hidden="true"></i>{props.event.category}
                        </div> : null
                    }
                    {
                        props.event.city ?
                            <div className='tags'>
                                <i className="fa fa-map-marker space-right" aria-hidden="true"></i>
                                {props.event.city} ,
                                {props.event?.location}
                            </div> : null
                    }
                    {
                        props.event.packagetype ? <div className='tags'><i className="fa fa-cutlery space-right"
                                                                           aria-hidden="true"></i>{props.event.packagetype}
                        </div> : null
                    }
                    {
                        props.event.budget_end ? <div className='tags'><i className="fa fa-inr space-right"
                                                                          aria-hidden="true"></i>{event.budget_end}
                        </div> : null
                    }
                    {
                        props.event.guests ? <div className='tags'><i className="fa fa-users space-right"
                                                                      aria-hidden="true"></i>{event.guests}
                        </div> : null
                    }
                </div>
            </div>
            {getVenueSegment()}
        </div>
    )
}

export default RecoVenueCombined;
