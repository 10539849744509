import callIdApi from "../../util/idApiCaller";
import callEventApi from "../../util/eventApiCaller";

export const LOADING_ALL_LEADS = 'LOADING_ALL_LEADS';
export const LOAD_ALL_LEADS = 'LOAD_ALL_LEADS';
export const TOGGLE_ALL_LEADS_FILTERS = 'TOGGLE_ALL_LEADS_FILTERS';

export function fetchAllLeads ({ pageNum, pageSize, filters }) {
    return (dispatch) => {
        dispatch({
            type: LOADING_ALL_LEADS,
            isFetchingLeads: true
        })
        return  callEventApi(`v3/admin-dashboard/all-leads`, 'POST', {
            pageNum: pageNum,
            pageSize: pageSize,
            filters: filters
        }).then(res => {
            console.log("res", res.data);
            if (res?.status === "Success") {
                dispatch({
                    type: LOAD_ALL_LEADS,
                    isFetchingLeads: false,
                    totalList: res?.data?.events,
                    totalCount: res?.data?.total
                })
            } else {
                dispatch({
                    type: LOADING_ALL_LEADS,
                    isFetchingLeads: false
                })
            }
        })
    }
}

export function toggleAllLeadsFilters (val) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_ALL_LEADS_FILTERS,
            isFiltersVisible: val
        })
    }
}
