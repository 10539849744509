import React, { useState, useEffect } from 'react';
import { Modal, Progress } from 'antd';

const InactivityCounterModal = (props) => {
  const { open, duration, onClose } = props;
  const [countdown, setCountdown] = useState(duration);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let timer;

    if (open) {
      timer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
          setProgress((countdown / duration) * 100);
        } else {
          clearInterval(timer);
          onClose();
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [open, countdown, duration, onClose]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      bodyStyle={{display:"flex", justifyContent:"center"}}

    >
      <div style={{display:"flex", justifyContent:"center",flexDirection:"column"}}>
        <Progress style={{display:"flex" , justifyContent:"center"}} strokeColor="red" type='circle' format={()=> countdown} percent={progress} />
        <h5>You will be automatically Logged Out Due To Inactivity</h5>
      </div>
    </Modal>
  );
};

export default InactivityCounterModal;

