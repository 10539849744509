import { CONFIG, IS_FETCHING_LOCATION, LOCATION, SET_OPERATIONAL_CITY_LIST , GET_VISIT_AGENTS_LIST, GET_NOTIFICATION_COUNT, GET_NOTIFICATION_LIST,FETCH_NOTIFICATION_STATUS} from "./AppActions"
import _ from 'lodash'



const initialState = {
    operationalCityList: [],
    location : {longitude :"", latitude :""},
    isFetchingLocation: false,
    configs:{},
    token: null,
    errorMessage: null,
    notificationcount:0,
    loadingnotification: false,
    allAgents: []
}





 const AppReducer = (state = initialState, action) => {

switch(action.type){
    case SET_OPERATIONAL_CITY_LIST:{

      let  operationalCities = action.cityList.map((item) => {
            item.label = item.name;
            item.value = item.slug;
            return item;
        });
        return {
            ...state,
            operationalCityList: operationalCities
        }
    }
    case GET_NOTIFICATION_COUNT : {
        return{
            ...state,
            notificationcount: action.notificationcount
        }
    }

    case GET_VISIT_AGENTS_LIST : {
        return{
            ...state,
            allAgents: action.allAgents
        }
    }
    case FETCH_NOTIFICATION_STATUS : {
        return{
            ...state,
            loadingnotification: action.loadingnotification
        }
    }
    case GET_NOTIFICATION_LIST : {
        return{
            ...state,
            notificationlist: action.notificationlist
        }
    }
    case LOCATION :{
        let longitude = action.longitude
        let latitude = action.latitude
        let location ={
            longitude:longitude,
            latitude:latitude
        }
        return {
            ...state,
            location:location
        }

    }

    case IS_FETCHING_LOCATION:{
        return{
            ...state,
            isFetchingLocation:action.isFetchingLocation
        }
    }
    case CONFIG:{
        return{
            ...state,
            configs:action.configs
        }
    }

    case 'VERIFY_TOTP_SUCCESS':
      return {
        ...state,
        token: action.payload.token,
        errorMessage: null,
      };
    case 'VERIFY_TOTP_FAILURE':
      return {
        ...state,
        token: null,
        errorMessage: action.payload.errorMessage,
      };
    default :return state      
}
}

export const getOperationalCityList = (state) => state.app.operationalCityList
export const getlocation =(state)=> state.app.location
export const isFetchingLocation =(state)=> state.app.isFetchingLocation
export const getConfigData =(state)=> state.app.configs
export const two_fa_errorMessage = (state) => state.app.errorMessage
export const getNotificationCount = (state) => state.app.notificationcount
export const getNotificationList = (state) => state.app.notificationlist
export const isFetchingNotificationList = (state) => state.app.loadingnotification
export const getAllVisitsAgents = (state) => state.app.allAgents




export default AppReducer;