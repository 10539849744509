import { Alert } from "antd";
import dayjs from "dayjs";
import React, {  useMemo } from "react";

export default function VisitAgentsMissingWarning({ venueList, assets }) {
  const addVisitDetailsForEachVenue = useMemo(() => {
    return (venueList, assets) => {
      let visitMap = [];
      for (let venue of venueList) {
        if (
          assets[venue.uid._id].visits &&
          assets[venue.uid._id].visits &&
          assets[venue.uid._id].visits.length > 0
        ) {
          for (let visit of assets[venue.uid._id].visits) {
            if (!visit.agentId || visit.agentId === "") {
              let missingAgent = {
                venue_name: venue.uid.name,
                visit_date: dayjs(visit.visit_date).format("DD-MM-YYYY hh:mm A"),
              };
              visitMap.push(missingAgent);
            }
          }
        }
      }
      return visitMap;
    };
  }, [venueList, assets]);

  return (
    <>
      {addVisitDetailsForEachVenue(venueList, assets).length > 0 && (
        <Alert
          showIcon
          message={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <>
                The following visits missing agents -
                {addVisitDetailsForEachVenue(venueList, assets).map(
                  (item, index) => (
                    <div key={index}>
                      Visit of {item.venue_name} on {item.visit_date}
                    </div>
                  )
                )}
              </>
            </div>
          }
          type="warning"
        />
      )}
    </>
  );
}
