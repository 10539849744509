import { Tag } from "antd";
import { UNAVAILABLE } from "../constants/venueCalendarEnums";
import { BOOKED } from "../constants/venueCalendarEnums";
import { STATUS_OPTIONS } from "../constants/venueCalendarEnums";
import { BOOKED_COLOR } from "../constants/venueCalendarEnums";
import { UNAVAILABLE_COLOR } from "../constants/venueCalendarEnums";
import { AVAILABLE_COLOR } from "../constants/venueCalendarEnums";
import { AVAILABLE } from "../constants/venueCalendarEnums";
import dayjs from "dayjs";


export const eventColorBasedOnStatus = (status) => {
    switch (status) {
      case AVAILABLE:
        return AVAILABLE_COLOR;
      case UNAVAILABLE:
        return UNAVAILABLE_COLOR;
      case BOOKED:
        return BOOKED_COLOR;
    }
  };

  export const getViewType=(type)=>{
    switch (type) {
      case "dayGridMonth":
        type = "month";
        break;
      case "timeGridWeek":
        type = "week";
        break;
      case "timeGridDay":
        type = "day";
        break;
      default:
        type = "month";
        break;
    }
    return type   
  }

  export const giveStatusValue=(status)=>{
  for(let option of STATUS_OPTIONS ){
    if(option.value==status){
      return option.index
    }
  }
  }

   export function isostringToCustomFormat(start_date, end_date) {
    try {
      const startDate = dayjs(start_date);
      const endDate = dayjs(end_date);

      const startTime = startDate.format('hA');
      const endTime = endDate.format('hA');

      const formattedDate = startDate.format('DD MMM YYYY');
      const timeRange = `${startTime} to ${endTime}`;
      return {
        formattedDate,
        timeRange
      }
    } catch (error) {
      return 'Invalid ISO date string';
    }
  }