export default [
    {
        isNormalLink: true,
        url: '/manager/tasks/board',
        name: 'My Tasks',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["mytasks:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/impTasks/board',
        name: 'My Imp Tasks',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["mytasks:read"],
    },
    // {
    //     isNormalLink: true,
    //     url: '/manager/funnel/inside-sales',
    //     name: 'My Inside Sales',
    //     icon: 'ti ti-view-list-alt',
    //     shouldHavePermissions: ["mytasks:read"],
    // },
    // {
    //     isNormalLink: true,
    //     url: '/manager/funnel/visit-sales',
    //     name: 'My Visit Sales',
    //     icon: 'ti ti-view-list-alt',
    //     shouldHavePermissions: ["mytasks:read"],
    // },
    {
        isNormalLink: true,
        url: '/manager/enquiry/my-leads-board',
        name: 'My Leads',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["myleads:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/enquiry/my-visits-board',
        name: 'My Visits',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["myleads:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/enquiry/leads-board',
        name: 'All Leads',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-download:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/enquiry/make-up-lead-board',
        name: 'All Make Up Leads',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-download:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/enquiry/visits-board',
        name: 'All Visits',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-download:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/call/info',
        name: 'My Call Data',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["mytasks:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/agents/call-data',
        name: 'Agents Call Data',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["admin:read"]
    },
    {
        isNormalLink: true,
        url: '/manager/login/info',
        name: 'My Login Data',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["mytasks:read"],
    },
    {
        isNormalLink: true,
        url: '/manager/agents/login/info',
        name: 'Agents Login Data',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["agentlogin:read"],
    },
    {
        isNormalLink: true,
        url: "/manager/marketing/events-dumper/list",
        name: "Events Dumper",
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-dumper:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/partner-lead-dumper/list",
        name: "Partner Lead Dumper",
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-dumper:read"],
      },
    {
        isNormalLink: true,
        url: '/manager/myfeedback/list',
        name: 'My Feedback',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["lead-download:read"],
    },
    // {
    //     isNormalLink: false,
    //     name: 'Venues',
    //     icon: 'ti ti-view-list-alt',
    //     shouldHavePermissions: ["venues:read"],
    //     list: [
    //         {
    //             isNormalLink: true,
    //             url: '/manager/venue/list',
    //             name: 'Venue Admin List',
    //             icon: '',
    //             shouldHavePermissions: ["venues:read"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/manager/venue/expert-list',
    //             name: 'Venue Expert List',
    //             icon: '',
    //             shouldHavePermissions: ["venues:read"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/manager/venue/drive-list',
    //             name: 'Venue Drive List',
    //             icon: '',
    //             shouldHavePermissions: ["venues:read"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/manager/venue/report',
    //             name: 'Reports',
    //             icon: '',
    //             shouldHavePermissions: ["admin:read"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/manager/venue/venue-leads',
    //             name: 'Venue Leads List',
    //             icon: '',
    //             shouldHavePermissions: ["bd-role:read"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/venues/data-transfer',
    //             name: 'Venue Data Transfer',
    //             icon: '',
    //             shouldHavePermissions: ["venues:update"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/venues/bulk-data-updator',
    //             name: 'Venue Bulk Data Updator',
    //             icon: '',
    //             shouldHavePermissions: ["venues:update"]
    //         },
    //         {
    //             isNormalLink: true,
    //             url: '/venues/faq-list',
    //             name: 'Venue FAQ List',
    //             icon: '',
    //             shouldHavePermissions: ["venues:update"]
    //         },
    //     ]
    // },
    {
        isNormalLink: false,
        name: 'Leads',
        icon: 'ti ti-control-shuffle',
        shouldHavePermissions: ["leads:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/enquiry/closed-won',
                name: 'Closed Won Enquiries ',
                icon: '',
                shouldHavePermissions: ["leads:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/enquiry/closed-lost',
                name: 'Closed Lost Enquiries ',
                icon: '',
                shouldHavePermissions: ["leads:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/enquiry/feedback',
                name: 'Feedback',
                icon: '',
                shouldHavePermissions: ["leads:read"],
            },
            {
                isNormalLink: true,
                url: '/manager/venuetarget/venuetargetlist',
                name: 'Venue Leads Target List',
                icon: '',
                shouldHavePermissions: ["leads:read"]
            }
        ]
    },
    {
        isNormalLink: false,
        name: 'Onboarding',
        icon: 'ti ti-view-list-alt',
        shouldHavePermissions: ["onboarding:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/venue/onboarding-list',
                name: 'Venue Onboarding List',
                icon: '',
                shouldHavePermissions: ["venueonboardings:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/venue/tracker-list',
                name: 'Venue Tracker List',
                icon: '',
                shouldHavePermissions: ["venuetrackers:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/photographer/onboarding-list',
                name: 'Photo Onboarding List',
                icon: '',
                shouldHavePermissions: ["photoonboardings:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/photographer/tracker-list',
                name: 'Photo Tracker List',
                icon: '',
                shouldHavePermissions: ["phototrackers:read"]
            }
        ]
    },
    {
        isNormalLink: false,
        name: 'Sales CRM',
        icon: 'ti ti-stats-up',
        shouldHavePermissions: ["mytasks:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/salescrm/bundleset/list',
                name: 'Bundle Set List',
                icon: '',
                shouldHavePermissions: ["mytasks:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/crm/funnelsystem/list',
                name: 'Funnel System List',
                icon: '',
                shouldHavePermissions: ["mytasks:read"]
            },
        ],
    },
    {
        isNormalLink: false,
        name: 'Feedback',
        icon: 'ti ti-stats-up',
        shouldHavePermissions: ["venues:read"],
        list: [
            {
                isNormalLink: true,
                url: '/manager/notfoundvenue/list',
                name: 'Not Found Venue List',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
            {
                isNormalLink: true,
                url: '/manager/futureevent/list',
                name: 'Future Event List',
                icon: '',
                shouldHavePermissions: ["bd-role:read"]
            },
        ]
    },

]
