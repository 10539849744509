import fetch from 'isomorphic-fetch';
import moment from 'moment';
const API_URL = process.env.REACT_APP_API_URL || `https://sales-apidev.weddingpe.com`;
//const API_URL = `http://localhost:8000`;

export function callApiAndCache(endpoint, method = 'get', body, expireHour) {
    let token =window.sessionStorage.getItem('token') ? window.sessionStorage.getItem('token') : '';
    let xToken = window.localStorage.getItem("x-token") || ''

    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    if(xToken && xToken!==''){
        headers["x-token"] = xToken 
    }
    let cacheResponse =window.sessionStorage.getItem(`api/${endpoint}`);
    if (cacheResponse) {
        let parsedResponse = JSON.parse(cacheResponse);
        if (parsedResponse.expires && moment(parsedResponse.expires).isAfter(moment())) {
            return Promise.resolve(parsedResponse.data);
        }
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            let cachedResponse = {
                data:  json,
                expires: moment().startOf('hour').add(expireHour ? expireHour : 1, 'hour').toISOString()
            }
           window.sessionStorage.setItem(`api/${endpoint}`, JSON.stringify(cachedResponse));
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

export default function callApi(endpoint, method = 'get', body) {
    let token =window.sessionStorage.getItem('token') ? window.sessionStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

