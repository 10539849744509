import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Select, Radio, Space, Spin } from "antd";
import dayjs from "dayjs";
import _ from "lodash";

import DatePicker from "react-multi-date-picker";

import { CalendarOutlined } from "@ant-design/icons";

import {
  SLOT_OPTIONS,
  STATUS_OPTIONS,
} from "../../../../../constants/venueCalendarEnums";
import FormItem from "antd/es/form/FormItem";

export default function AddEventForm(props) {
  const [form] = Form.useForm();
  const [isAdding, setIsAdding] = useState(false);
  const [isDateRange, setIsDateRange] = useState(true);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.selectInfo)) {
      form.setFieldsValue({
        date: [
          new Date(props.selectInfo.start),
          new Date(props.selectInfo.end),
        ],
      });
    }
  }, [props.selectInfo]);

  const onClose = () => {
    props.resetSelectInfo();
    props.setAddDrawerVisible(false);
  };

  const onFinish = () => {
    form.validateFields().then(() => {
      setIsAdding(true);
      let value = form.getFieldsValue();
      let payload = {};

      if (isDateRange) {
        payload.start_date = dayjs(value.date?.[0])
          .startOf("day")
          .toISOString();
        payload.end_date = dayjs(value.date?.[1]).startOf("day").toISOString();
      } else {
        payload.specific_dates = value.date?.map((date) =>
          dayjs(date).startOf("day").toISOString()
        );
      }

      payload.time_slot = value.slot;
      payload.time_slot_status = value.status;
      payload.venuespaces = value.venuespaces
        ? value.venuespaces
        : [props.venue._id];

      if (isDateRange) {
        props
          .handleEventAdd(payload)
          .then(() => {
            setIsAdding(false);
            onClose();
          })
          .catch(() => {
            setIsAdding(false);
          });
      } else {
        props
          .handleSpecificAdd(payload)
          .then(() => {
            setIsAdding(false);
            onClose();
          })
          .catch(() => {
            setIsAdding(false);
          });
      }
    });
  };

  const dateFieldValidator = () => {
    let selectedDates = form.getFieldValue("date");

    if (isDateRange) {
      if (selectedDates && selectedDates.length === 2) {
        let diff = dayjs(selectedDates[1])
          .startOf("day")
          .diff(dayjs(selectedDates[0]).startOf("day"), "days");
        if (diff >= 1) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            "Start date and End date should have a difference of atleast 1 day"
          );
        }
      } else {
        return Promise.reject("Please select both Start and End Dates");
      }
    } else {
      if (selectedDates && selectedDates.length >= 1) {
        setSelectedDates(selectedDates);
        return Promise.resolve();
      } else {
        return Promise.reject("Please select at least one date");
      }
    }
  };

  const venueSpaceValidator = () => {
    let venueSpaces = form.getFieldValue("venuespaces");
    if (props.venueSpacesList.length <= 0) {
      return Promise.resolve();
    } else {
      if (venueSpaces && !_.isEmpty(venueSpaces)) {
        return Promise.resolve();
      } else return Promise.reject("VenueSpace Cannot be empty");
    }
  };

  return (
    <Drawer
      title="Add Event"
      placement="right"
      onClose={() => onClose()}
      open={props.open}
      width={400}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" onClick={onClose} disabled={isAdding}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isAdding}
            onClick={onFinish}
          >
            Ok
          </Button>
        </div>
      }
    >
      <Spin spinning={isAdding}>
        <Form form={form} name="eventForm" layout="vertical" initialValues={{ dateSelection : true}}>
          <Form.Item
            name="dateSelection"
            label={<b>Date Selection</b>}
            rules={[{ required: true, message: "Please select a date type" }]}
          >
            <Radio.Group
              defaultValue={true}
              onChange={(e) => {
                setIsDateRange(e.target.value);
                form.setFieldsValue({ date: [] });
                setSelectedDates([])
              }}
            >
              <Radio value={true}>Range</Radio>
              <Radio value={false}>Multi</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="date"
            label={<b>{isDateRange ? "From-To" : "Select Dates"}</b>}
            rules={[
              {
                validator: dateFieldValidator,
              },
            ]}
          >
            {isDateRange ? (
              <DatePicker
                render={<CustomInput placeholder={"Select Range"} />}
                range={true}
              />
            ) : (
              <DatePicker
                render={<CustomInput placeholder={"Select Dates"} />}
                multiple={true}
              />
            )}
          </Form.Item>

          {selectedDates && selectedDates.length > 0 && (
            <FormItem
              style={{ marginTop: "5px", width: "100%" }}
              label={<b>Selected Dates:</b>}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedDates.map((date, index) => (
                  <div
                    key={date}
                    style={{ margin: "2px", whiteSpace: "nowrap" }}
                  >
                    {dayjs(date).format("DD MMM YYYY")}
                    {index !== selectedDates.length - 1 && <>{" ,"}</>}
                  </div>
                ))}
              </div>
            </FormItem>
          )}

          <Form.Item
            name="slot"
            label={<b>Slot</b>}
            rules={[{ required: true, message: "Slot needed to be selected" }]}
          >
            <Select
              size={"large"}
              style={{ width: "140px" }}
              mode="multiple"
              dropdownStyle={{ zIndex: "2500" }}
            >
              {SLOT_OPTIONS.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="venuespaces"
            label={<b>Venue spaces</b>}
            rules={[{ validator: venueSpaceValidator }]}
          >
            <Select
              showSearch
              dropdownStyle={{ zIndex: "2500" }}
              style={{ width: "150px" }}
              mode="multiple"
              disabled={props.venueSpacesList.length <= 0}
            >
              {props.venueSpacesList.map((item) => (
                <Select.Option value={item._id} key={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="status"
            rules={[{ required: true, message: "Status Cannot be empty" }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {STATUS_OPTIONS.map((item) => (
                  <Radio value={item.value} key={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

function CustomInput({ onFocus, value, onChange, placeholder }) {
  console.log(value,"valueee")

  const icon =   <div
  style={{
    position: "absolute",
    top: "50%",
    right: "8px",
    transform: "translateY(-50%)",
    cursor: "pointer",
  }}
>
  <CalendarOutlined />
</div>

  const renderIcon =()=>{
    if (placeholder == "Select Dates") return icon
    else if (value == "" || _.isEmpty(value) || !value) return icon
    else return <></>
    
  }
  return (
    <div style={{ position: "relative" }}>
      <input
        onFocus={onFocus}
        value={value}
        onChange={onChange}
        style={{
          border: "1px solid #c0c4d6",
          borderRadius: "5px",
          height: "22px",
          margin: "1px 0",
          padding: "18px 18px",
          color: placeholder == "Select Dates" ? "transparent" : "black",
          fontSize:"12px"
        }}
        placeholder={placeholder}
      />
      {
       renderIcon()
      
      }
     
    </div>
  );
}
