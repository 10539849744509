import React from 'react';

export const Sections = ({ onChange, activeSections, children }) => {
  const sections = React.Children.toArray(children);

  return (
    <div>
      {sections.map((section, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          {React.cloneElement(section, {
            isActive: activeSections.includes(section.props.eventKey),
            onClick: () => onChange(section.props.eventKey),
          })}
          {activeSections.includes(section) && section.props.children}
        </div>
      ))}
    </div>
  );
};

export const Section = ({ title, isActive, onClick, children }) => (
  <>
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        fontWeight: 'bold',
        backgroundColor: isActive ? '#e0e0e0' : '#f4f4f4', 
        width: '100%',
        position: 'relative',
        display: 'flex',
        padding: '15px', 
        borderRadius: '5px', 
        alignItems: 'center',
      }}
    >
      <div style={{ marginLeft: "10px", flex: 1 }}>{title}</div>
      <span
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize:"20px"
        }}
      >
        {isActive ? '▲' : '▼'}
      </span>
    </div>
    {isActive && (
      <div style={{ padding: '10px', backgroundColor: '#ffffff', borderRadius: '5px', marginTop: '5px' }}>
        {children}
      </div>
    )}
  </>
);
