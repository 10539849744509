import {
    FETCH_AGENT_LIST,
    FETCH_FUNNEL_DATA,
    FETCH_TASK_CONTENT,
    FETCH_TASK_STATS,
    FETCH_CURRENT_TASK,
    FETCH_BASIC_PARAMETERS,
    FETCH_LOCATION_PARAMETERS,
    NEW_EVENT_CREATED,
    UPDATE_EVENT,
    UPDATE_CUSTOMER_EVENT_INFO,
    UPDATE_TASKS_INTO_LIST,
    UPDATING_TASKS_INTO_LIST,
    FETCH_EVENT_INFO,
    FETCH_BOOKMARKED_LEADS, UPDATE_EVENT_STATUS_POST_BOOKMARK,
    UPDATE_FILTER_WISE_COUNT, UPDATE_TOTAL_COUNT, APPEND_TASK_DATA, UPDATE_TOTAL_COUNT_WITHOUT_FILTERS,
    HANDLE_VISIT_MODAL_STATE
} from "./SalesAgentQueueActions";

const initialState = {
    agent_list: [],
    isFetching: false,
    tasks: [],
    taskCount: 0,
    taskCountwithoutFilters:0,
    funnel_data: {},
    salestask: [],
    closedLostReasons: [],
    callingStatusList: [],
    agentStats: [],
    agentCallData: [],
    scores: [],
    current_task: {},
    items: [],
    counts: "",
    baseParams: [],
    subAreas: [],
    newEvent: false,
    event: [],
    currentTaskEvent: [],
    bookmarkedList: [],
    filterCounts: [],
    isVisitModalOpen :false
};

const SalesReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_VISIT_MODAL_STATE :
            return {
                ...state,
                isPlanVisitModalOpen : action.isVisitModalOpen
             }
        case APPEND_TASK_DATA:
            return {
                ...state,
                tasks: [...state.tasks, ...action.tasks],
                isFetching: action.isFetching
            }
        case UPDATE_TOTAL_COUNT:
            return {
                ...state,
                taskCount: action.count
            }
        case UPDATE_TOTAL_COUNT_WITHOUT_FILTERS:
            return {
                ...state,
                taskCountwithoutFilters: action.count
            }
        case UPDATE_FILTER_WISE_COUNT:
            let tempCounts = {...state.filterCounts}
            tempCounts[action.filter._id] = action.count;
            return {
                ...state,
                filterCounts: tempCounts
            }
        case FETCH_AGENT_LIST:
            return {
                ...state,
                agent_list: action.agent_list,
            };

        case FETCH_FUNNEL_DATA:
            return {
                ...state,
                funnel_data: action.funnel_data,
            };

        case FETCH_TASK_CONTENT:
            return {
                ...state,
                salestask: action.taskContent.salestask,
                closedLostReasons: action.taskContent.closedLostReasons,
                callingStatusList: action.taskContent.callingStatusList,
            };

        case FETCH_TASK_STATS:
            return {
                ...state,
                scores: action.taskStats.scores,
                agentCallData: action.taskStats.agentCallData,
                agentStats: action.taskStats.agentStats,
            };

        case FETCH_CURRENT_TASK:
            return {
                ...state,
                count: action.current_task.total,
                items: action.current_task.tasks,
            };

        case FETCH_BASIC_PARAMETERS:
            return {
                ...state,
                baseParams: action.baseParams,
            };

        case FETCH_LOCATION_PARAMETERS:
            return {
                ...state,
                subAreas: action.subAreas,
            };

        case NEW_EVENT_CREATED:
            return {
                ...state,
                newEvent: action.newEvent,
            };

        case UPDATE_EVENT:
            return {
                ...state,
                items: action.items,
            };

        case UPDATE_CUSTOMER_EVENT_INFO:
            return {
                ...state,
                event: action.event,
            };

        case FETCH_BOOKMARKED_LEADS:
            return {
                ...state,
                bookmarkedList: action.bookmarkedList,
            };

        case UPDATE_TASKS_INTO_LIST:
            let tasks = [];
            tasks = action.tasks.map(item =>
                {
                return {
                    ...item,
                    ...item.event,
                    task_note: item.note,
                    _id: item.event._id,
                    task_id: item._id,
                }
            })
            return {
                ...state,
                tasks: tasks,
                isFetching: action.isFetching,
            };

        case UPDATING_TASKS_INTO_LIST:
            return {
                ...state,
                isFetching: action.isFetching,
                tasks: action.tasks,
                taskCount: action.taskCount,
            };
        case FETCH_EVENT_INFO:
            return {
                ...state,
                currentTaskEvent: action.currentTaskEvent,
            };

        case UPDATE_EVENT_STATUS_POST_BOOKMARK:
            let new_tasks = state.tasks.map(item => {
                if (item.eventid === action.event_id) {
                    return {
                        ...item,
                        ...action.event,
                        task_id: item._id,
                    }
                }
                return item;
            });
            return {
                ...state,
                tasks: new_tasks,
            };

        case FETCH_EVENT_INFO:
            return {
                ...state,
                currentTaskEvent: action.currentTaskEvent,
            };


        default:
            return state;
    }
};

/* Selectors */

export const getAgentTaskLoadingState = (state) => state.sales.isFetching;
export const getAgentTasks = (state) => state.sales.tasks;
export const getAgentTaskCount = (state) => state.sales.taskCount;
export const getAgentList = (state) => state.sales.agent_list;
export const getFunnelData = (state) => state.sales.funnel_data;
export const getSalestask = (state) => state.sales.salestask;
export const getClosedLostReasons = (state) => state.sales.closedLostReasons;
export const getCallingStatusList = (state) => state.sales.callingStatusList;
export const getSalesAgentScore = (state) => state.sales.scores;
export const getSalesCallData = (state) => state.sales.agentCallData;
export const getSalesAgentStats = (state) => state.sales.agentStats;
export const getCurrentTaskCount = (state) => state.sales.count;
export const getCurrentTaskTasks = (state) => state.sales.items ? state.sales.items : [];
export const getBaseParams = (state) => state.sales.baseParams;
export const getSubAreas = (state) => state.sales.subAreas;
export const getNewEventStatus = (state) => state.sales.newEvent;
export const getUpdateEventInfo = (state) => state.sales.event;
export const getCurrentEventInfo = (state) => state.sales.currentTaskEvent;
export const getBookmarkedList = (state) => state.sales.bookmarkedList;
export const getFiltersCount = (state) => state.sales.filterCounts;
export const getAllLeadsCountWithoutFilters =(state)=> state.sales.taskCountwithoutFilters
export const getPlanVisitModalState =(state)=> state.sales.isPlanVisitModalOpen

export default SalesReducer;
