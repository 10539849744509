import {GLOBAL_CHANNEL} from "../../Connector";
import callApi from '../../util/apiCaller';
import callEventApi from '../../util/eventApiCaller';
import callRTCService from "../../util/rtcApiCaller";

export const OPEN_AGENT_BUTTON = "OPEN_AGENT_BUTTON";
export const CLOSE_AGENT_BUTTON = "CLOSE_AGENT_BUTTON";
export const FETCH_ONLINE_AGENTS = "FETCH_ONLINE_AGENTS";
export const FETCH_KEYS = "FETCH_KEYS";
export const SET_ONLINE_USERS = "SET_ONLINE_USERS";
export const TOTAL_ONLINE_USER = "TOTAL_ONLINE_USER";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const openAgentButton = () => {
    return {
        type: OPEN_AGENT_BUTTON,
        openAgentList: true,
        showFabButton: false
    };
};

export const closeAgentButton = () => {
    return {
        type: CLOSE_AGENT_BUTTON,
        openAgentList: false,
        showFabButton: true
    };
};

export const fetchOnlineUsers = () => {
    return dispatch => {
        console.log("jere");
        callRTCService(`heartbeat/online-users`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                dispatch({
                    type: FETCH_ONLINE_AGENTS,
                    onlineUsers: res.data
                })
            }
        });
    }
}

export const setPubNubCreds = (pubnubCredentials) => {
    return {
        type: FETCH_KEYS,
        pubnubCredentials: pubnubCredentials
    }
}
export const openModalForm = () => {
    return {
        type: OPEN_MODAL,
        isOpenModal: true
    }
}
export const closeModalForm = () => {
    return {
        type: CLOSE_MODAL,
        isOpenModal: false
    }
}
export const configApiCall = () => {
    return dispatch => {
        callEventApi(`config`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                const credentials = {
                    publishKey: res.data.pubnub_publisher_key,
                    subscribeKey: res.data.pubnub_subscriber_key,
                }
                dispatch(setPubNubCreds(credentials))
            }
        }).catch(err => {
            if (err) {
                console.log(err);
                dispatch(setPubNubCreds({hey: 1, bae: 2}))
            }
        })
    }

}

export const setOnlineUserList = (onlineUserList) => {
    return {
        type: SET_ONLINE_USERS,
        onlineUserList: onlineUserList
    }
}

export const totalOnlineUser = (value) => {
    return {
        type: TOTAL_ONLINE_USER,
        totalOnlineUser: value
    }
}

function getOnlyEventUUIDs(onlineUsers, agentId) {
    let occupants = onlineUsers.map(item => item.event_id);
    return occupants;
}

function getEventUUIds(occupants) {
    return occupants.filter(item => item.toString().includes("event-")).map(item => item.toString().substring(6, item.toString().length));
}

export const agentCredentialsApiCall = (onlineUser, agentId) => {
    return dispatch => {
        const eventIds = getOnlyEventUUIDs(onlineUser, agentId);
        const eventList = [...new Set(eventIds)];
        const totalOnlineUsers = eventList.length;
        if (totalOnlineUsers) {
            dispatch(totalOnlineUser(totalOnlineUsers))
        }
        if (eventList) {
            callEventApi(`v2/events/events-from-ids`, 'POST', {eventIds: eventList}).then(res => {
                if (res && res.status === 'Success') {
                    if (res.data?.events?.length > 0) {
                        dispatch(setOnlineUserList(res?.data?.events))
                    } else {
                        dispatch(setOnlineUserList([]))
                    }
                }
            }).catch(err => {
                if (err) {
                    console.log('error', err);
                    dispatch(setOnlineUserList([]))
                }
            })
        } else {
            dispatch(setOnlineUserList([]))
        }
    }
}
