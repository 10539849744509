import React, {Component} from 'react';
import NormalLink from './NormalLink';
import DropDown from './DropDown';
import AccessControl from "../PermissionLocks/AccessControl";
import {connect} from 'react-redux';
import {  fetchAgentList } from '../../redux/admin/UserActions';

class Sidebar extends Component {
    constructor() {
        super();
        this.state = {
            firstLinks: [{
                isNormalLink: true,
                url: '/',
                name: 'Profile',
                icon: 'ti ti-home'
            }],
        };
    }

componentDidMount() {
    this.props.dispatch(fetchAgentList());
}

    render() {
        let links = this.state.firstLinks;
        links = links.concat(this.props.links);
        return (
            <div className="static-sidebar-wrapper sidebar-default">
                <div className="static-sidebar">
                    <div className="sidebar">
                        <div className="widget stay-on-collapse" id="widget-sidebar">
                            <nav role="navigation" className="widget-body">
                                <ul className="acc-menu">
                                    {links.map(item => (
                                        item.isNormalLink ?
                                            <AccessControl
                                                userPermissions={this.props.userPermissions}
                                                shouldHavePermissions={item.shouldHavePermissions ? item.shouldHavePermissions : []}
                                            >
                                                <NormalLink
                                                    key={item.url}
                                                    url={item.url}
                                                    name={item.name}
                                                    icon={item.icon}
                                                    activeUrl={window && window.location ? window.location.pathname : null}
                                                />
                                            </AccessControl>
                                            :
                                            <AccessControl
                                                userPermissions={this.props.userPermissions}
                                                shouldHavePermissions={item.shouldHavePermissions ? item.shouldHavePermissions : []}
                                            >
                                                <DropDown
                                                    userPermissions={this.props.userPermissions}
                                                    key={item.name}
                                                    name={item.name}
                                                    icon={item.icon}
                                                    list={item.list}
                                                    activeUrl={window && window.location ? window.location.pathname : null}
                                                />
                                            </AccessControl>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(Sidebar);
