import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Space, } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import callIdApi from '../../util/idApiCaller';
import showToastMessage from '../../util/notificationUtil';


export default function ResetPassword() {
    const [form] = Form.useForm();

    const [email,setEmail]=useState("")
    const [code ,setCode]=useState("")

    useEffect(() => {
      const queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);
      if (searchParams.has("email") && searchParams.has("reset_token")) {
        let email = searchParams.get("email");
        let code = searchParams.get("reset_token");
        setEmail(email)
        setCode(code)
      }
    }, []);

    const resetPassword = async(password)=>{
      if(code && !code.trim("")==""){
      try{
        let res = await callIdApi(`auth/reset-password/${code}`,`post`,{password:password})
        if(res.status="Success"){
          showToastMessage("Successfull change password","success",3000)
          setTimeout(()=>{
            window.location = "/";
          },3000)
        }
        else{
          showToastMessage(res?.data?.message? res?.data?.message : "Some Error Occored","error")
        }
      }
      catch(e){
        console.log(e)
      }
    }
    else{
      showToastMessage("Token is Invalid","error")
    }
        

      }

    
    const onFinish = (values) => {
      resetPassword(values.password)
      };
      return (
        <div className="container" style={{ marginTop: 50 }}>
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h2>Reset Password</h2>
                </div>
                <div className="panel-body">
                  <Form
                    form={form}
                    onFinish={onFinish}
                   layout='vertical'
                    id="validate-form"
                  >
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        visibilityToggle
                      />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('The two passwords do not match!')
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

  