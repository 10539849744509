import {toast} from "react-toastify";

export default function showToastMessage(text, notificationType = "success", isTimedNotification = true, timer = 3000) {
    const options = {
        autoClose: isTimedNotification ? timer : false,
        pauseOnHover: true, 
        closeOnClick: true, 
        draggable: true 
    };

    switch (notificationType) {
        case "success":
            toast.success(text, options);
            break;
        case "error":
            toast.error(text, options);
            break;
        case "warning":
            toast.warn(text, options);
            break;
        default:
            toast.info(text, options);
            break;
    }
}