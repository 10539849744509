export const have_us_call_you_lead_sources=[
    "have-us-call-you-lead-form-embedded",
    "customer-have_us_call_you_form-desktop",
    "customer-have_us_call_you_form-mobile",
    "wa-call-me-form"

]
export const connect_to_vm_lead_sources=[
    "customer-connect_to_vm_form-mobile",
    "customer-connect_to_vm_form-desktop"
]
export const free_food_tasting_lead_sources=[
    "customer-free_food_tasting_form-desktop",
    "customer-free_food_tasting_form-mobile"

]

export const leadSourceText = {
    "have_us_call_you" : "Call Me",
    "free_food_tasting" : "Need Free Food Tasting",
    "connect_to_vm":"Connect me to VM"
}


export const CUSTOMER_VISIT_PLANNED = "Customer Visit Planned"