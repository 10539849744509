import callEventApi from "../../util/eventApiCaller";
import showToastMessage from "../../util/notificationUtil";
import utility from "../../util/utility";
import moment from 'moment';

export const PROCESS_DIALER_CALL = "PROCESS_DIALER_CALL";

export const processCallToDialer = (phone, event_id) => async (dispatch, getState) => {
    try {
        if (phone && utility.isValidIndianMobile(phone)) {
            dispatch({
                type: PROCESS_DIALER_CALL,
                isProcessingCall: true,
            });

            const agent = getState().user?.agent;
            if (!agent.teleforce_id || agent.teleforce_id == "") {
                showToastMessage("Feature is currently not Available for you", 'error');
                throw new Error("NO TELEFORCE ID");
            }

            const res = await callEventApi(`dialer/call-via-teleforce`, 'post', {
                
                    eventId: event_id,
                    agent,
                    contact_phone: phone,
                
            });

            if (res.status === 'Success') {
                showToastMessage('Call Processed', 'success');
            } else {
                showToastMessage('Call Failed', 'error');
            }

            dispatch({
                type: PROCESS_DIALER_CALL,
                isProcessingCall: false,
            });
        } else {
            showToastMessage('Please enter a valid Indian Number', 'error');
        }
    } catch (error) {
        console.error(error);
    }
};

