export const getVisitStatus=(visit)=>{
    if(visit){
        if(visit.isVisitCompleted){
            return "VisitDone"
        }
        else if(visit.isConfirmedByVenue){
            return "Confirmed"
        }
        else{
            return "Pending"
        }
    }
   
}