import {
    LOADING_ALL_LEADS,
    LOAD_ALL_LEADS, TOGGLE_ALL_LEADS_FILTERS
} from "./AllLeadActions";

const initialState = {
    totalList: [],
    isFetchingLeads: false,
    totalCount: 0,
    pageNum: 1,
    pageSize: 50,
    activeFilters: {},
    isFiltersVisible: false,
};

const AllLeadsReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOADING_ALL_LEADS:
            return {
                ...state,
                isFetchingLeads: action.isFetchingLeads
            };

        case TOGGLE_ALL_LEADS_FILTERS:
            return {
                ...state,
                isFiltersVisible: action.isFiltersVisible
            };

        case LOAD_ALL_LEADS:
            return {
                ...state,
                isFetchingLeads: action.isFetchingLeads,
                totalList: action.totalList,
                totalCount: action.totalCount,
            };

        default:
            return state;
    }
};

/* Selectors */
export const getAllLeadList = (state) => state.alleads.totalList;
export const getAllLeadsLoadingState = (state) => state.alleads.isFetchingLeads;
export const getAllLeadsCount = (state) => state.alleads.totalCount;
export const getAllLeadsPageNum = (state) => state.alleads.pageNum;
export const getAllLeadsPageSize = (state) => state.alleads.pageSize;
export const getAllLeadsActiveFilters = (state) => state.alleads.activeFilters;
export const getAllLeadsFiltersVisible = (state) => state.alleads.isFiltersVisible;

export default AllLeadsReducer;
