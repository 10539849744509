import React from 'react';
import moment from 'moment';
import {
} from 'react-bootstrap';

function EmailSent(props) {
    if (props.mails && props.mails.length > 0) {
        return (
            <div className="panel panel-default">
               
                <table className="table">
                    <thead>
                    <tr>
                        <th>Sent Date</th>
                        <th>Sent To</th>
                        <th>Status</th>
                        <th>Delivered</th>
                        <th>Opened</th>
                        <th>Bounced</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.mails.map((mail) => (
                        <tr className="prev-details" key={mail._id}>
                            <td>{moment(mail.created_on).format("Do MMM YYYY")}</td>
                            <td>
                                {mail.emailTo && mail.emailTo !== '' ? `${JSON.parse(mail.emailTo).map(item => item.email).join(', ')}` : ''}
                                {mail.emailCc && mail.emailCc !== '' ? `${JSON.parse(mail.emailCc).map(item => item.email).join(', ')}` : ''}
                            </td>
                            <td>
                                {mail.status}
                            </td>
                            <td>
                                {mail.isMailDelivered ? <i className="fa fa-check"/> : <i className="fa fa-times" />}
                            </td>
                            <td>
                                {mail.isMailOpened ? <span><i className="fa fa-check"/>(mail.mailOpenedCount)</span> : <i className="fa fa-times" />}
                            </td>
                            <td>
                                {mail.isMailBounced ? <i className="fa fa-check"/> : <i className="fa fa-times" />}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className="panel panel-default">
           
                <div className="panel-body">
                    <div className="text-center">
                        No Mails Yet
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailSent;
