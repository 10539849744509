import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {Link} from 'react-router-dom';
import styles from './notification.module.css'
import moment from "moment";
import {Pagination} from "react-bootstrap";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';


dayjs.extend(relativeTime);




function NotificationList({ notificationList ,notificationMark, notificationAllMark,loadMoreNotifications,count,pageSize,page}) {

 const getPages = (count, pagesize) => {
    if (count > 0) {
        return Math.ceil(count / pagesize);
    } else {
        return 0;
    }
};


  console.log(notificationList, "LISTSTTSTTSTSTSTTS")

  return (
    <div style={{ padding: "20px", width: '100%' }}>
      <div className={styles['wrapper']}>
        <span className={styles['heading']}>All Notifications</span>
        <button className={styles['button-container']} style={{ width: '140px' }} onClick={() => notificationAllMark()}>
          <span style={{ color: 'white' }}>All Mark Read</span>
        </button>
      </div>
      <div style={{ marginTop: '40px' }}>
        {notificationList &&
          notificationList.list.map((notification, index) => {
            const isRead = notification.isRead;

            return (
              <div
                key={notification._id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  backgroundColor : (isRead ? '' : '#dfe9f2'),
                  padding: '10px',
                  position: 'relative'
                }}
                className={styles["notification-card"]}
              >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {notification.title}
                    </span>
                    <p style={{ fontSize: "16px", fontWeight: "500",marginTop:'10px'}}>
                            {notification.body} <p style={{marginTop:'10px',display:'flex',flexDirection:'row',gap:'30px'}}>  <b>{dayjs(notification.created_on).fromNow()}</b> <a href={notification.payload.url} style={{ textDecoration: 'none' }} onClick={()=> notificationMark(notification._id)}> <i title="Open Enquiry" className="fa fa-external-link"></i></a> 
                
                            {
                    !isRead &&   <a onClick={()=> notificationMark(notification._id)}> <i title="Mark Read" className="fa fa-eye"></i></a> } </p>
                          </p>
                  </div>
                  <div style={{ alignSelf: 'center' }}>
                    {!isRead  && (
                      <div style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: 'blue', alignSelf: 'center' }} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {/* <button onClick={() => loadMoreNotifications()}>
          <span>Load More</span>
        </button> */}
        <div className="dataTables_paginate paging_bootstrap" id="example_paginate">
          <Pagination
            className="m-n pull-right"
            prev
            next
            first
            last
            items={getPages(count, pageSize)}
            maxButtons={4}
            activePage={page}
            onSelect={(page) => loadMoreNotifications(page)} />
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
