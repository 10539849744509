import React, {useState} from 'react';
import { Button } from "react-bootstrap";
import moment from 'moment';
import callEventApi from "../../../util/eventApiCaller";
import showToastMessage from "../../../util/notificationUtil";

function EventAlgos({ event_id }) {

    const [isCreatingToken, setTokenCreation] = useState(false);

    const createMagicToken = async () => {
        setTokenCreation(true);
        let result = await callEventApi(`v3/admin-dashboard/event/${event_id}/create-magic-token`, 'post', {})
        setTokenCreation(false);
        if (result.status === "Success") {
            showToastMessage("Created!");
        } else {
            showToastMessage("Error !" , "error");
        }
    }

    return (
        <div className="row">
            <div className="col-xs-12">
                <div className="list-group-item">
                    <div className="row">
                        <div className="col-xs-6">
                            Magic Token Algo
                        </div>
                        <div className="col-xs-6">
                            <Button disabled={isCreatingToken} onClick={() => createMagicToken()}>
                                {isCreatingToken ? "Creating..." : "Create Magic Token"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventAlgos;
