import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./AvailabilityCalender.css";
import dayjs from "dayjs";


import AddEventForm from "./components/AddEventForm";
import _ from "lodash";
import UpdateEventForm from "./components/AvailabilityEditForm";
import AvailablityCalendarHeader from "./components/AvailablityCalendarHeader";

import { Select, Spin } from "antd";
import { getViewType,eventColorBasedOnStatus } from "../../../../util/venueCalendar";
import { IMPORTANT_DATES_COLOR, VENUE_SPACE_COLOR_OPTIONS } from "../../../../constants/venueCalendarEnums";
import callApi from "../../../../util/managerAPICaller";
import showToastMessage from "../../../../util/notificationUtil";
import callVenueApi from "../../../../util/venueAPICaller";

export default function AvailablityCalendar(props) {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [eventsCount, setEventsCount] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [venueSpacesList, setVenueSpacesList] = useState([]);
  const [isAddDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectInfo, setSelectInfo] = useState({});
  const [eventObjMap, setEventObjMap] = useState({});
  const [isUpdateDrawerVisible, setUpdateDrawerVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isLoading,setIsLoading]=useState(false)
  const [ appliedFilters ,setAppliedFilters] = useState({})
  const [currentViewStartDate,setCurrentViewStartDate]=useState(dayjs().toISOString())
  const [currentViewEndDate,setCurrentViewEndDate]=useState(dayjs().toISOString())
  const [importDates,setImportantDates] = useState([])
  const [importantDatesLoaded,setImportantDatesLoaded]=useState(false)
  

  const calendarRef = React.useRef();

  useEffect(()=>{
     getImportantDates({
      start_date: dayjs().startOf('year').add(7,"days").toISOString(),
      end_date: dayjs().endOf('year').add(7,"days").toISOString(),
    }).then((value)=>{
      setImportantDates(value)
      setImportantDatesLoaded(true)
    })
  },[])

  const makeEventVenueSpaceObjectMap = (eventsList, venueSpaceList) => {
    let modal = {};
    if (
      eventsList &&
      venueSpaceList &&
      eventsList.length > 0 &&
      venueSpaceList.length > 0
    ) {
      for (let event of eventsList) {
        for (let venueSpace of venueSpaceList) {
          if (event.venuespace_id == venueSpace._id) {
            modal[event._id] = {};
            modal[event._id].venue = props.venue.name;
            modal[event._id].venueSpaceId = venueSpace._id;
            modal[event._id].venueSpaceName = venueSpace.name;
            modal[event._id].venueSpaceColor = venueSpace.color;
            modal[event._id].slot = event.time_slot;
            modal[event._id].status = event.time_slot_status;
          }
        }
      }
    } else {
      for (let event of eventsList) {
        modal[event._id] = {};
        modal[event._id].venueSpaceId = props.venue._id;
        modal[event._id].venueSpaceName = props.venue.name;
        modal[event._id].slot = event.time_slot;
        modal[event._id].status = event.time_slot_status;
      }
    }
    return modal;
  };

  const getVenueSpaceName = (eventId, eventObjMap) => {
    if (eventObjMap && !_.isEmpty(eventObjMap)) {
      return eventObjMap[eventId]?.venueSpaceName
        ? eventObjMap[eventId]?.venueSpaceName
        : props.venue.name;
    } else return props.venue.name;
  };

  const refreshList = async (filters = {},newFiltersApplied) => {
    let modal = {};
    if(!newFiltersApplied){
      filters={...filters,...appliedFilters}
    }
    try {
    setIsLoading(true)
      modal.eventsList = await getAvailablityEvents(props.venue._id, filters);
      let venueSpaces = await fetchVenueSpaces();
      let venueSpaceList = [];
      venueSpaces.forEach((item, index) => {
        item.color = VENUE_SPACE_COLOR_OPTIONS[index];
        venueSpaceList.push(item);
      });
      modal.venueSpaceList = venueSpaceList;
      modal.eventObjMap = makeEventVenueSpaceObjectMap(
        modal.eventsList,
        modal.venueSpaceList
      );
      if (modal.eventsList && modal.venueSpaceList) {
        modal.transformedList = transformAvailablityList(
          modal.eventsList,
          modal.eventObjMap
        );
      }
      setEventsList(modal.eventsList);
      setVenueSpacesList(modal.venueSpaceList);
      if (modal.eventObjMap) {
        setEventObjMap(modal.eventObjMap);
      }
      setCurrentEvents(modal.transformedList);
      setEventsCount(modal.eventsList.length);
      setIsLoading(false)
    } catch (e) {
      console.log(e);
    }
  };

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const resetSelectInfo = () => {
    setSelectInfo({});
  };

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    let type= getViewType(selectInfo.view.type)
     if(type=="month"){
       setSelectInfo(selectInfo);
       setAddDrawerVisible(true);
     }
     else{
       calendarApi.unselect()
       showToastMessage("This Feature is currently available in month view only",'warning')
     }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event.toPlainObject());
    setUpdateDrawerVisible(true);
  };

  const dateCircleStyle=(date_type)=>{
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor:IMPORTANT_DATES_COLOR[date_type].backgroundColor ,
      color: IMPORTANT_DATES_COLOR[date_type].textColor,
    };

  }




  function renderDayCell(dayCellContent) {
  

    let matchingImportDate = null;

    importDates.some((importantDate) => {
      if (dayjs(importantDate.event_date).isSame(dayCellContent.date, 'day')) {
        matchingImportDate = importantDate;
        return true; 
      }
      return false;
    });
  
    if (matchingImportDate) {
      return (
        <div style={dateCircleStyle(matchingImportDate.date_type)} title={matchingImportDate.name}>
          {dayCellContent.dayNumberText}
        </div>
      );
    }
  
    return <div>{dayCellContent.dayNumberText}</div>;
  }

  const fetchVenueSpaces = async () => {
    try {
      let res = await callApi(
        `v2/venuespaces/venue/${props.venue._id}/list`,
        "get"
      );
      if (res.status === "Success") {
        return res.data.venuespace;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  };



  const transformAvailablityList = (venuecalenderList, eventObjMap) => {
    return venuecalenderList.map((item) => {
      return {
        id: item._id,
        title: _.truncate(getVenueSpaceName(item._id, eventObjMap),"20"),
        start: dayjs(item.start_date).toISOString(),
        end: dayjs(item.end_date).toISOString(),  
        color: eventColorBasedOnStatus(item.time_slot_status),
        textColor: "white",
      };
    });
  };

  const getAvailablityEvents = async (venue_id, filters = {}) => {
    if (venue_id) {
      try {
        let res = await callVenueApi(
          `v1/venue-calender/venue/${venue_id}/event-list`,
          "Post",
          { filters: filters }
        );
        if ((res.status = "Success")) {
          return res.data.venuecalenderList;
        } else {
          return [];
        }
      } catch (e) {
        showToastMessage("error in fetching list", "error", 3000);
        console.log(e);
      }
    }
  };



  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const getVenueSpaceColor = (eventid) => {
    if (venueSpacesList.length <= 0) {
      return VENUE_SPACE_COLOR_OPTIONS[0];
    } else {
      return eventObjMap?.[eventid]?.venueSpaceColor
        ? eventObjMap?.[eventid]?.venueSpaceColor
        : VENUE_SPACE_COLOR_OPTIONS[VENUE_SPACE_COLOR_OPTIONS.length - 1];
    }
  };
  const renderEventContent = (eventInfo) => {
    let eventId = eventInfo.event.toPlainObject().id
    return (
      <div
        className="event-main"
      >
        <div
          style={{
            width: "10%",
            backgroundColor: "#FFEEBF",
            display:"flex",
            justifyContent:"center"
          }}
        ><b style={{color:"#1A1A1A" }}>{eventObjMap?.[eventId]?.slot[0].toUpperCase()}</b></div>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };


  const handleEventBulkAdd = async (payload) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/venue/${props.venue._id}/bulk-create`,
        "post",
        payload
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showToastMessage("success", "success", 3000);
        
      }
      else{
        showToastMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSpecificAdd = async (payload) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/venue/${props.venue._id}/specific-bulk-create`,
        "post",
        payload
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showToastMessage("success", "success", 3000);
        
      }
      else{
        showToastMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleEventEdit = async (eventid,payload) => {
    try {
      let body ={venuecalender:payload}
      let res = await callVenueApi(
        `v1/venue-calender/venue/${props.venue._id}/venuecalendar/${eventid}/update-event`,
        "post",
        body
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showToastMessage("success", "success", 3000);
        
      }
      else{
        showToastMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEventRemove = async (eventId) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/${eventId}/remove`,
        "post"
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showToastMessage("success", "success", 3000);
        
      }
      else{
        showToastMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addToFilters=(obj)=>{
   setAppliedFilters({...appliedFilters,...obj})
  }

  const cellData = (cell) => {
    // if(dayjs(convertToDayString(cell.date)).isSame(dayjs(convertToDayString(dayjs())))){
    //   let a = cell.el
    //   a.children[0].style.backgroundColor = "red"
    //   console.log(a.children[0].childNodes[2], "CHILDREN")
    // } //=> to be done later
  };

  const getImportantDates =async (filters)=>{
    try{
      let res = await callVenueApi('v1/important-dates/important-dates-list','post',{filters})
      if(res.status=="Success"){
      return res.data.importantDatesList 
      }else{
        showToastMessage("Error Fetching Important Dates")
      }

    }
    catch(e){
      console.log(e)
    }

  }

  const viewChange = () => {};

  const dateSet = async (dateInfo) => {
    setCurrentViewStartDate(dateInfo.start);
    setCurrentViewEndDate(dateInfo.end);
  
    let type = getViewType(dateInfo.view.type);
    const startDate = dayjs(dateInfo.start).startOf(type).startOf('year').toISOString();
    const endDate = dayjs(dateInfo.end).endOf(type).endOf('year').toISOString();
  
    const yearChanged =
      !_.isEqual(dayjs(dateInfo.start).year(), dayjs(currentViewStartDate).year()) ||
      !_.isEqual(dayjs(dateInfo.end).year(), dayjs(currentViewEndDate).year());
  
    if (yearChanged) {  
        const importantDates = await getImportantDates({ start_date: startDate, end_date: endDate });
        setImportantDates(importantDates);
      
    }
  
    refreshList({
      start_date: startDate,
      end_date: endDate,
    });
  };

  const customButtons = {
    addEventButton: {
      text: " + ",
      click: () => {
        setAddDrawerVisible(true);
      },
    },
  };

  return (
    <div className="calendar-app">
      {importantDatesLoaded ? <div className="calendar-main">
        <AvailablityCalendarHeader
          venueSpaceList={venueSpacesList}
          venueId={props.venue.id}
          refresh={refreshList}
          addToFilters={addToFilters}
          currentViewStartDate={currentViewStartDate}
          currentViewEndDate={currentViewEndDate}
        />
       <Spin spinning={isLoading}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          customButtons={customButtons}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "addEventButton dayGridMonth,timeGridWeek,timeGridDay",
          }}
          viewDidMount={viewChange}
          event
          initialView="dayGridMonth"
          ref={calendarRef}
          nowIndicatorContent
          eventDisplay="block"
          // editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          dayCellContent={renderDayCell}
          datesSet={dateSet}
          dayCellDidMount={cellData}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          events={currentEvents}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          //  you can update a remote database when these fire:
          // eventAdd={handleEventBulkAdd}
          eventChange={function () {}}
          eventRemove={function () {}}
        />
        </Spin>
      </div> : <>loading</>
      
    }

      
      {isAddDrawerVisible && (
        <AddEventForm
          open={isAddDrawerVisible}
          resetSelectInfo={resetSelectInfo}
          venue={props.venue}
          handleEventAdd={handleEventBulkAdd}
          venueSpacesList={venueSpacesList}
          selectInfo={selectInfo}
          setAddDrawerVisible={setAddDrawerVisible}
          handleSpecificAdd={handleSpecificAdd}
        />
      )}
      {isUpdateDrawerVisible && (
        <UpdateEventForm
        userPermissions={props.userPermissions}
          update={handleEventEdit}
          remove={handleEventRemove}
          eventObjMap={eventObjMap}
          open={isUpdateDrawerVisible}
          venue={props.venue}
          venueSpacesList={venueSpacesList}
          event={selectedEvent}
          setUpdateDrawerVisible={setUpdateDrawerVisible}
        />
      )}
    </div>
  );
}
