import { combineReducers } from 'redux';
import app from './redux/admin/AppReducer'
import agent from './redux/admin/OnlineCustomerReducer.js';
import sales from './redux/admin/SalesAgentsQueueReducer';
import event from './redux/admin/EventReducer';
import dialer from './redux/admin/DialerReducer';
import user from './redux/admin/UserReducer';
import alleads from './redux/admin/AllLeadsReducer';
import availaibilityCalendar from './redux/admin/AvailaibilityCalendarReducer';

const rootReducer = combineReducers({
    app,
    sales,
    event,
    agent,
    user,
    dialer,
    alleads,
    availaibilityCalendar
});

export default rootReducer;
