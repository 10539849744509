
import { SET_CALENDAR_VISIBILITY, SET_SELECTED_VENUE } from "./AvailaibilityCalendarActions";

const initialState = {
    calendarVisible: false,
    selectedVenue: null,
  };
  
  const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CALENDAR_VISIBILITY:
        return {
          ...state,
          calendarVisible: action.payload,
        };
  
      case SET_SELECTED_VENUE:
        return {
          ...state,
          selectedVenue: action.payload,
        };
  
      default:
        return state;
    }
  };

  export const getAvailaibilityCalendarVisibilityState =(state)=> state.availaibilityCalendar.calendarVisible
  export const getAvailaibilityCalendarSelectedVenue =(state)=> state.availaibilityCalendar.selectedVenue
  
  export default calendarReducer;
  