import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEventCallLogs } from '../../../../redux/admin/EventReducer'
import EventCallLogsRow from './EventCallLogsRow'

export default function EventCallLogsTable() {
  const dispatch = useDispatch()
  let callLogs = useSelector((state)=>getEventCallLogs(state))



  return (
    <div style={{maxHeight:"600px", overflow:"auto"}}>
               
    <table className="table table-striped table-fixed-header table-bordered dataTable no-footer">
        <thead>
        <tr>
            <th>S.no</th>
            <th>Agent Name</th>
            <th>Call Start Time</th>
            <th>Call Type</th>
            <th>Customer Pick Up Time</th>
            <th>Call End Time</th>
            <th>Call Duration</th>
            <th>Agent Call Status</th>
            <th>Customer Call Status</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {callLogs.map((log,index) => (
           <EventCallLogsRow
           index={index}
           key={log._id}
           log={log}
           />
        ))}
        </tbody>
    </table>
</div>
  )
}
