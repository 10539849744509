import React, {useState, useEffect} from "react";
import packageJson from "../meta.json";
import {Modal, Button} from "react-bootstrap";
import { connect } from 'react-redux';
import {clearAllLocalStorageKeys} from "../redux/admin/UserActions";
global.appVersion = packageJson.version;

const CACHE_TIME_OUT =
    parseInt(process.env.REACT_APP_APP_REFRESH_TIMEOUT, 10) || 30;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

const AppVersionModal = ({isAuthenticated}) => {
    const [latestVersion, setLatestVersion] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const getCacheStatus = () => {
        console.log("herhe");
        fetch(`/meta.json?v=${new Date().getTime()}`)
            .then((response) => response.json())
            .then((meta) => {
                const latestUpdatedVersion = meta.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(
                    latestUpdatedVersion,
                    currentVersion,
                );
                if (shouldForceRefresh) {
                    setLatestVersion(latestUpdatedVersion);
                    setIsModalVisible(true);
                }
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        if (isAuthenticated) {
            setTimeout(() => {
                getCacheStatus();
            }, 1000 * 60 * CACHE_TIME_OUT);
        }
    }, [isAuthenticated]);

    const handleCloseModel = () => {
        setIsModalVisible(false);
    };

    const handleConfirm = () => {
        if (caches) {
            caches.keys().then((names) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const name of names) caches.delete(name);
            });
            this.props.dispatch(clearAllLocalStorageKeys());
            setTimeout(() => {
                if (window) {
                    window.location.reload(true);
                }
            }, 300);
        }
    };

    if (isModalVisible) {
        return (
            <Modal
                show={isModalVisible}
                size={'small'}
                centered={'centered'}
                onHide={handleCloseModel}
            >
                <modalbox>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'New Version Update'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "200px", overflowY: "scroll", textAlign: "center"}}>
                        <div className="panel">
                            <div className="panel-body">
                                <p>
                                    {`We have a new version - ${latestVersion}`}
                                </p>
                                <p>
                                    Do you want to update the app ?
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={'btn-default'} onClick={() => handleCloseModel()}>
                            Not Now
                        </Button>
                        <Button className="btn-primary"
                                onClick={() => handleConfirm()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </modalbox>
            </Modal>
        );
    }
    return null;
};

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(AppVersionModal);
