import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Layout, Spin, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { version } from "../../meta.json";
import {
  EnvironmentOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { getLocationData, verifyTotp } from "../../redux/admin/AppActions";
import Sider from "antd/es/layout/Sider";
import { Content } from "react-bootstrap/lib/Tab";
import { notify } from "react-notify-toast";
import { Carousel } from "antd";
import {
  getConfigData,
  isFetchingLocation,
} from "../../redux/admin/AppReducer";
import { borderRadius, width } from "@mui/system";
import { ArrowLeftOutlined } from "@ant-design/icons";
import showToastMessage from "../../util/notificationUtil";

const Login = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isResettingEmail, setIsResettingEmail] = useState(false);
  const dispatch = useDispatch();
  const fetchingLocation = useSelector(isFetchingLocation);
  const configs = useSelector(getConfigData);
  const [requestOtp, setRequestOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [agentId, setAgentId] = useState("");

  const token = sessionStorage.getItem("token");
  const user_id = window.localStorage.getItem("user_id");

  useEffect(() => {
    if (user_id) {
      setAgentId(user_id);
    }
  },[user_id]);

  const [form] = Form.useForm();

  const submitForm = async (e) => {
    const creds = {
      email: e.username,
      password: window.btoa(e.password),
    };
    let res = await props.loginUser(creds);
    console.log(res, " ");
    if (res && res !== "") {
      if (res.message == "request_otp") {
        setRequestOtp(true);
        setAgentId(res.agentId);
      }
    }
  };

  const handleResetPass = async (email) => {
    setIsResettingEmail(true);
    if (email && !email.trim("") == "") {
      await props.sendForgetPassword(email);
      setIsResettingEmail(false);
    } else {
      notify.show("email cannot be empty");
      setIsResettingEmail(false);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleDetectLocation = async () => {
    try {
      dispatch(getLocationData());
    } catch (e) {
      console.log(e);
    }
  };

  const handleOtpSubmission = async ({ otp }) => {
    dispatch(verifyTotp(otp, agentId)).then(() => {
      window.location = "/";
    });
  };
  useEffect(() => {
    if (token && !requestOtp && props.isAuthenticated) {
      setRequestOtp(true);
    }
  },[token,requestOtp,props.isAuthenticated]);

  if (props.isAuthenticated) {
    return (
      <Redirect
        to={
          props.location && props.location.state && props.location.state._refurl
            ? props.location.state._refurl
            : "/"
        }
      />
    );
  }

  const backgroundStyle = {
    backgroundImage: `url(https://cdn.venuemonk.com/assets/t-12-341/images/original/frame-772547069-758107.png)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "70vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "60px",
  };

  const contentStyle = {
    width: "640px",
    height: "480px",
    borderRadius: "8px",
    // height:"271px"
    // background: "#364d79",
  };

  const siderContainer = {
    width: "100%",
    padding: "20px 50px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };

  const handleOtpBack = () => {
    window.sessionStorage.removeItem("token");
    setRequestOtp(false);
  };

  const handleClearSiteData = () => {
    let confirm = window.confirm('You want to clear Site Data');
    if(confirm) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.caches.keys().then(function(names) {
        for (let name of names)
          window.caches.delete(name).then(res => {
            console.log(name, 'cache cleared')
          });
      });
      window.location.reload(true);
    }
  }

  return (
    <div>
      <Layout
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <Content style={backgroundStyle}>
          <a href="/">
            <img
              src="https://cdn.venuemonk.com/vm_underline_highres.png"
              width={"300px"}
              alt="VenueMonk Logo"
            />
          </a>

          <div
            style={{
              width: "640px",
              height: "480px",
              borderRadius: "8px",
            }}
          >
            <Carousel autoplay>
              {configs?.banner_images?.map((image, index) => (
                <div key={index}>
                  <img
                    style={contentStyle}
                    src={image}
                    alt={`Image ${index}`}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Content>
        <Sider
          className="siderMain"
          trigger={null}
          collapsible={false}
          width="30vw"
          theme="light"
          height="100vh"
        >
          <div style={siderContainer}>
            <h2
              style={{
                font: "Segoe UI",
                color: "black",
                marginBottom: "30px",
                fontWeight: "600",
                fontSize: "28px",
              }}
            >
              Welcome to VenueMonk
            </h2>
            <h2
              style={{
                font: "Segoe UI",
                color: "black",
                fontWeight: "700",
                marginBottom: "40px",
                fontSize: "22px",
              }}
            >
              Login
            </h2>
            <Button
              type="primary"
              loading={fetchingLocation}
              onClick={handleDetectLocation}
              ghost
              style={{ width: "100%", height: "40px", marginBottom: "10px" }}
            >
              <EnvironmentOutlined />
              Detect My Location
            </Button>
            {requestOtp && (
              <Button
                type="default"
                onClick={handleOtpBack}
                style={{ width: "20%", height: "40px", marginTop: "10px" }}
              >
                <ArrowLeftOutlined />
              </Button>
            )}

            <Form
              size="large"
              onFinish={!requestOtp ? submitForm : handleOtpSubmission}
              rootClassName="siderMain"
              name="normal_login"
              style={{ marginTop: "20px" }}
              className="login-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
            >
              {!requestOtp ? (
                <>
                  {" "}
                  <Form.Item
                    name="username"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input
                      style={{ padding: "12px 8px" }}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ padding: "12px 8px" }}
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      visibilityToggle
                    />
                  </Form.Item>{" "}
                </>
              ) : (
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter OTP",
                    },
                  ]}
                >
                  <Input
                    style={{ padding: "12px 8px" }}
                    placeholder="Enter OTP"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", height: "40px" }}
                  className="login-form-button"
                >
                  {!requestOtp ? "Log in" : "Submit OTP"}
                </Button>
              </Form.Item>
              <Form.Item>
                <a
                  style={{ float: "right" }}
                  className="login-form-forgot"
                  onClick={() => setShowResetForm(!showResetForm)}
                >
                  {!showResetForm ? "Forgot password" : "Close"}
                </a>
              </Form.Item>
            </Form>
            {showResetForm && (
              <div>
                <Form
                  size="large"
                  name="inline_reset_form"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  layout="inline"
                >
                  <Form.Item
                    name="resetEmail"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                    style={{ flex: 1, marginRight: "8px" }}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Enter Email"
                      onChange={(e) => setResetEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      onClick={() => handleResetPass(resetEmail)}
                      type="primary"
                    >
                      Send Reset Link
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </Sider>
        <h4
            onClick={handleClearSiteData}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 0,
            color: "black",
            left: 0,
            backgroundColor: "white",
            width: "80px",
          }}
        >{`V ${version}`}</h4>
      </Layout>
    </div>
  );
};

export default Login;
