export const LOCALSTORAGE_CAMPAIGNS = 'LOCALSTORAGE_CAMPAIGNS';
export const SALES = 'sales';

export const VISIT_SALES = 'visit-sales';
export const INSIDE_SALES = 'inside-sales';


export const AGENTVERSIONV1 = "v1";
export const AGENTVERSIONV2 = "v2";

export const  SALES_TRACKING_INTERVAL = 5000 // in ms
