import React, { useState, useEffect } from 'react';
import callIdApi from '../../util/idApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { verifyTotp } from '../../redux/admin/AppActions';
import { two_fa_errorMessage } from '../../redux/admin/AppReducer';

const GoogleAuthSetup = () => {
  const dispatch = useDispatch();

  const [otpAuthUrl, setOTPAuthUrl] = useState('');
  const [otpInput, setOTPInput] = useState('');
  const [error, setError] = useState('');
  const [agentId, setAgentId] = useState('');
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const errorMessage = useSelector(two_fa_errorMessage);

  useEffect(() => {
    let agentId = window.localStorage.getItem('user_id');
    if (!agentId || agentId === '') {
      setPermissionDenied(true);
    } else {
      setAgentId(agentId);
    }
  }, []);

  useEffect(() => {
    if (agentId && agentId !== '') {
      generateQrLink(agentId).then((url) => {
        setOTPAuthUrl(url);
      });
    }
  }, [agentId]);

  const generateQrLink = async (agentid) => {
    try {
      let res = await callIdApi(`auth/agent/${agentid}/generate-qr-code`);
      if (res.image_data) {
        return res.image_data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOTPInputChange = (event) => {
    setOTPInput(event.target.value);
  };

  const handleSubmit = async () => {
    if (otpInput && otpInput !== '') {
      dispatch(verifyTotp(otpInput, agentId))
        .then((res) => {
          setSuccessMessage('OTP Verified successfully. Redirecting To Login Page Please Login Again...');
          sessionStorage.removeItem("token")
          setTimeout(() => {
            window.location = '/';
          }, 3000);
        })
        .catch((err) => {
          setError('Failed to verify OTP. Please try again.');
        });
    } else {
      setError('OTP IS REQUIRED');
    }
  };
  if(successMessage){
    return (

      <p style={{ ...styles.success, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          {successMessage}
        </p>
      

    )
  }

  return (
    <>
      {permissionDenied ? (
        <div className={'text-center'}>
          <h1>{'You Don\'t Have Permission To Access This Page'}</h1>
        </div>
      ) : (
        <div style={styles.container}>
          <h1 style={styles.heading}>Setup Google Authenticator</h1>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.qrCodeContainer}>
            <p>Scan the QR code with Google Authenticator:</p>
            {otpAuthUrl && <img src={otpAuthUrl} alt="QR Code" style={styles.qrCode} />}
          </div>
          <div style={styles.inputContainer}>
            <p>Enter the OTP below:</p>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otpInput}
              onChange={handleOTPInputChange}
              style={styles.inputField}
            />
            <button onClick={handleSubmit} style={styles.submitButton}>
              Submit
            </button>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    margin: '50px auto',
    maxWidth: '400px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  success: {
    color: 'green',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  qrCodeContainer: {
    marginBottom: '20px',
  },
  qrCode: {
    width: '100%',
    maxWidth: '200px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputField: {
    margin: '10px 0',
    padding: '8px',
    fontSize: '16px',
  },
  submitButton: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default GoogleAuthSetup;
