export const BREAK_OPTIONS =[
    {
        label :"Lunch",
        value : "lunch",
        reason_code : 1

    },
    {
        label :"Tea",
        value : "tea",
        reason_code : 2

    },
    {
        label :"Dinner",
        value : "dinner",
        reason_code : 3

    },
    {
        label :"Breakfast",
        value : "breakfast",
        reason_code : 4

    },
    {
        label :"Washroom",
        value : "washroom",
        reason_code : 5

    },
]