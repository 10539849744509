import React, {useState} from "react";
import {getAsset, getEvent, getVenueList} from "../../../redux/admin/EventReducer";
import {getActiveAgent} from "../../../redux/admin/UserReducer";
import {useDispatch, useSelector} from 'react-redux';
import {Button, ButtonGroup, FormControl} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _ from 'lodash';
import moment from "moment";
import Handlebars from 'handlebars';
import he from 'he';

function EventTemplate(props) {
    const [smstext, setSmsText] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [isTemplateTextToBeUsed, setIsTemplateTextToBeUsed] = useState(false);
    const activeAgent = useSelector(state => getActiveAgent(state));
    const currentevent = useSelector(state => getEvent(state));
    const currentVenueList = useSelector(state => getVenueList(state));
    const assets = useSelector(state => getAsset(state));

    const dispatch = useDispatch();
    const handleSmsTextChange = (e) => {
        setSmsText(e.target.value);
    };

    const handleNewTemplateChange = (description, template) => {
        let input = getInputTemplateData();
        let templateBody = Handlebars.compile(template);
        let smstext = templateBody(input).toString();
        smstext = he.decode(smstext);

        setSelectedTemplate(description)
        setSmsText(smstext)
    };

    const toggleTemplateCopy = () => {
        setIsTemplateTextToBeUsed(!isTemplateTextToBeUsed)
    };

    const sendEmail = () => {
        let packageList = [];
        currentVenueList.forEach(venue => {
            if (!_.isEmpty(assets[venue.uid._id])) {
                assets[venue.uid._id].subpackages.filter(item => item.isPackageSelected).map(item => {
                    packageList.push({
                        packageId: item._id,
                        venueId: venue.uid._id
                    });
                });
            }
        });
        props.send(isTemplateTextToBeUsed, packageList);
    };
    const getInputTemplateData = () => {
        let input = {};
        let event = _.clone(currentevent);
        input.event = event;
        input.event.occasion = event.occasion[0] ? event.occasion[0] : '';
        input.event.venuetype = event.venuetype[0] ? event.venuetype[0] : '';
        input.event.event_date = event.event_date ? moment(event.event_date).format('DD MMM YYYY') : '';
        input.event.city = currentevent.city ? _.startCase(currentevent.city) : '';
        input.event.agent_name = activeAgent.name;
        input.event.agent_phone = activeAgent.phone;
        input.event.agent_email = activeAgent.email;
        let venueList = {};
        currentVenueList.forEach(venue => {
            if (!_.isEmpty(assets) && !_.isEmpty(assets[venue.uid._id])) {
                assets[venue.uid._id].subpackages.filter(item => item.isPackageSelected).map(item => {
                    if (venueList[venue.uid._id] && venueList[venue.uid._id].subpackages) {
                        venueList[venue.uid._id].subpackages.push({
                            packagename: item.name,
                            price: item.package_price,
                        });
                    } else {
                        venueList[venue.uid._id] = {
                            venueId: venue.uid._id,
                            packageId: item._id,
                            venueName: venue.uid.name,
                            manager_name: venue.manager_name,
                            manager_phone: venue.manager_phone,
                            shortlink: venue.uid.shortlink,
                            menuLink: venue.uid.menuLink,
                            visitDate: `${moment(venue.visit_date).format('Do MMM YYYY')} - ${moment(venue.visit_date).format('HH:mm')}`
                        };
                        venueList[venue.uid._id].subpackages = [];
                        venueList[venue.uid._id].subpackages.push({
                            packagename: item.name,
                            price: item.package_price,
                        });
                        if (!_.isEmpty(assets[venue.uid._id]) && !_.isEmpty(assets[venue.uid._id].contact)) {
                            venueList[venue.uid._id].contactName = `${assets[venue.uid._id].contact.first_name}`;
                            venueList[venue.uid._id].contactPhone = `${assets[venue.uid._id].contact.contact_phone}`;
                        } else {
                            venueList[venue.uid._id].contactName = '';
                            venueList[venue.uid._id].contactPhone = '';
                        }
                    }
                });
            }
        });
        let venues = [];
        for (let key in venueList) {
            venues.push(venueList[key]);
        }
        input.venues = venues;
        input.selectedVenueCount = _.keys(venueList).length;
        return input;
    };

    return (<>
            <div className={'panel-body'}>
                <div className="row">
                    <div className="col-xs-4">
                        {props.eventtemplates && props.eventtemplates.map(item => (
                            <Button
                                className={'full-width'}
                                onClick={() => handleNewTemplateChange(item.description, item.template)}
                            >
                                <span>{item.description}</span>
                            </Button>
                        ))}
                    </div>
                    <div className="col-xs-8">
                        <form className="form-horizontal tabular-form">
                            <div className="form-group">
                                <div className="col-xs-12">
                                    {selectedTemplate}
                                </div>
                                <div className="col-sm-12 tabular-border">
                                    <FormControl
                                        onChange={(e) => handleSmsTextChange(e)}
                                        style={{minHeight: "430px"}}
                                        className="full-width"
                                        componentClass="textarea"
                                        value={smstext}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="panel-footer">
                        <div className="row">
                            <div className="col-sm-8 col-sm-offset-2">
                                <ButtonGroup>
                                    <Button
                                        disabled={props.isSending}
                                        onClick={() => sendEmail()}
                                        className="btn-default"
                                    >{props.isSending ? "Sending To Customer" : "Send To Customer"}
                                    </Button>
                                    <Button
                                        onClick={() => toggleTemplateCopy()}
                                    >
                                        {isTemplateTextToBeUsed ?
                                            <i className="fa fa-check"/> :
                                            <i className="fa fa-times"/>
                                        }
                                    </Button>
                                    <a
                                        className="btn btn-default"
                                        href={`https://api.whatsapp.com/send?phone=91${props.event.contact_phone}`}
                                        target="_blank">
                                        <i style={{color: "#491369"}} className={"fa fa-whatsapp"}/>
                                    </a>
                                    <CopyToClipboard text={smstext}
                                                     onCopy={() => props.showMessage('copied', 'success')}>
                                        <Button><i className="fa fa-clipboard"/></Button>
                                    </CopyToClipboard>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default EventTemplate;