import React, { useEffect, useState } from "react";
import NotificationList from "../../modules/Admin/AdminNotifications/components/NotificationList";
import {
  fetchnotificationlist,
  notificationMarkRead,
} from "../../redux/admin/AppActions";
import {
  getNotificationList,
  isFetchingNotificationList,
} from "../../redux/admin/AppReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FadingCircleLoader from "../Loader/FadingCircleLoader";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import styles from "../../modules/Admin/AdminNotifications/components/notification.module.css";

function NotificationModal({ userid, onclose, notificationAllMark }) {
  const list = useSelector((state) => getNotificationList(state));
  const isloading = useSelector((state) => isFetchingNotificationList(state));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchnotificationlist("agent", userid, 1));
  }, [userid]);

  const notificationMark = (id) => {
    if (id) {
      dispatch(notificationMarkRead(id, "agent", userid, 1));
    } else {
      console.log("Notification ID is missing");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {isloading ? (
        <div className="loading-spinner">
          <FadingCircleLoader size={42} />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{ color: "blue", fontSize: "28px", fontWeight: "800" }}
            >
              Notifications
            </span>
            <div style={{ alignSelf: "center" }}>
              <Link to={"/manager/notifications"}>
                <span
                  style={{ color: "blue", fontSize: "18px", marginTop: "10px" }}
                  onClick={() => onclose()}
                >
                  {" "}
                  See all
                </span>
              </Link>
            </div>
            <div style={{ alignSelf: "center" }}>
              <button
                style={{
                  border: "1px solid blue",
                  backgroundColor: "blue",
                  borderRadius: "4px",
                  padding: "4px",
                }}
                onClick={() => notificationAllMark()}
              >
                <span style={{ color: "white", fontSize: "12px" }}>
                  Mark All Read
                </span>
              </button>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            {list &&
              list?.list?.map((notification, index) => {
                const isRead = notification.isRead;

                return (
                  <div
                    style={{
                      backgroundColor: isRead ? "" : "#dfe9f2",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      className={styles["notification-card"]}
                    >
                      <div
                        key={notification._id}
                        style={{
                          padding: "20px",
                        }}
                      >
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {notification.title}
                        </span>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          {notification.body}{" "}
                          <p
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "row",
                              gap: "30px",
                            }}
                          >
                            {" "}
                            <b>
                              {dayjs(notification.created_on).fromNow()}
                            </b>{" "}
                            <a
                              href={`${notification.payload.url}`}
                              style={{ textDecoration: "none" }}
                              onClick={() => notificationMark(notification._id)}
                            >
                              {" "}
                              <i
                                title="Open Enquiry"
                                className="fa fa-external-link"
                              ></i>
                            </a>
                            {!isRead && (
                              <a
                                onClick={() =>
                                  notificationMark(notification._id)
                                }
                              >
                                {" "}
                                <i title="Mark Read" className="fa fa-eye"></i>
                              </a>
                            )}{" "}
                          </p>
                        </p>
                      </div>
                      <div style={{ alignSelf: "center", marginRight: "15px" }}>
                        {!isRead && (
                          <div
                            style={{
                              width: 12,
                              height: 12,
                              borderRadius: "50%",
                              backgroundColor: "blue",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationModal;
