import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Tag } from 'antd';
import { eventColorBasedOnStatus, isostringToCustomFormat } from '../../../util/venueCalendar';

export default function CalendarStatusTable(props) {

  const  customAvailabilitydiv=(start_date,end_date,time_slot)=>{
   let customformat= isostringToCustomFormat(start_date,end_date)
   return(<div style={{display:"flex" }}><div style={{marginRight:"4px"}} >{customformat.formattedDate}</div><Tag title={customformat.timeRange} color='magenta'>{time_slot}</Tag></div>)

  }

  
 
  

  console.log(props.CalendarEvent);

  return (
    <div style={{padding:"0 1em"}}>
      <table>
        <thead>
          <tr>
            <th style={{width:"100px"}}>VenueSpace</th>
            <th style={{width:"160px"}}>Date Range</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.CalendarEvent?.map((record, index) => (
            <tr key={index}>
              <td> <div style={{
    display: 'flex',
    maxWidth: '100px',      
    overflowWrap: 'break-word',
    flexDirection:"column" 
  }}>{record.venueSpaceName || props.venueName}</div></td>
    
              
              <td>{customAvailabilitydiv(record.start_date, record.end_date, record.time_slot)}</td>
              <td><Tag color={eventColorBasedOnStatus(record.time_slot_status)}>{_.startCase(_.toLower(record.time_slot_status))}</Tag></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
