import callIdApi from "../../util/idApiCaller";

export const SET_AGENT = 'SET_AGENT';
export const SET_AGENT_LIST = 'SET_AGENT_LIST';

export function setAgent(agent) {
    return (dispatch) => {
        dispatch({
            type: SET_AGENT,
            agent: agent
        });
    };
}


export function setAgentList(agentList) {
    return (dispatch) => {
        dispatch({
            type: SET_AGENT_LIST,
            agentList: agentList
        });
    };
}



export function fetchAgentList () {
    return (dispatch) => {
       return  callIdApi(`v3/agents/agent-list`, 'POST', {}).then(res => {
            if (res.status === "Success") {
                dispatch(setAgentList(res?.data?.agents))
            }
        });
    }
}

export function clearAllLocalStorageKeys () {
    return (dispatch) => {
        Object.keys(localStorage).filter(x =>
            x.startsWith('api/')).forEach(x =>
            localStorage.removeItem(x))
    }
}
