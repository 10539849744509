export const SET_CALENDAR_VISIBILITY ='SET_CALENDAR_VISIBILITY'
export const SET_SELECTED_VENUE ='SET_SELECTED_VENUE'





export const setAvailabilityCalendarVisibility = (isVisible) => ({
    type: SET_CALENDAR_VISIBILITY,
    payload: isVisible,
  });
  
  export const setAvailaibilityCalendarSelectedVenue = (venue) => ({
    type: SET_SELECTED_VENUE,
    payload: venue,
  });
  