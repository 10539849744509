import React, { useState } from "react";
import { Select, Input, Table, Button } from "antd";
import _ from "lodash";
import callApi from "../util/managerAPICaller";

const { Option } = Select;

const VenueSearchComponent = ({
  cities,
  onSelectVenueChange,
  header,
  selectStyle,
}) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [venueList, setVenueList] = useState([]);

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const handleSearchChange = async (e) => {
    await searchForCity(selectedCity, _.kebabCase(e.target.value));
  };

  const searchForCity = async (city, text) => {
    let res = await callApi(`searchbox/venue?city=${city}&term=${text}`, "get");
    if (res && res.status === "Success") {
      if (res.data.venues) {
        setVenueList(res.data.venues);
      }
    } else {
      console.log("Search item failed", res);
    }
  };

  const handleSelectVenue = (venue) => {
    onSelectVenueChange(venue);
  };

  const columns = [
    {
      title: "Venue Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          {record.name}, {record.city}
        </span>
      ),
    },
    {
      title: "Select",
      key: "select",
      render: (text, record) => (
        <Button onClick={() => handleSelectVenue(record)}>Select Venue</Button>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div style={selectStyle}>
          <div style={{ marginBottom: "10px" }}>{header}</div>
          <div style={{ display: "flex" }}>
            <Select
              style={{ width: "140px", marginRight: "10px" }}
              placeholder="Select City"
              onChange={handleCityChange}
            >
              {cities.map((item) => (
                <Option key={item._id} value={item.name}>
                  {_.startCase(item.label)}
                </Option>
              ))}
            </Select>
            <Input
              style={{ width: "200px", marginRight: "10px" }}
              placeholder="Search Name Here"
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      {venueList && venueList.length > 0 && (
        <Table
          dataSource={venueList}
          columns={columns}
          pagination={false}
          scroll={{ y: 350 }}
        />
      )}
    </div>
  );
};

export default VenueSearchComponent;
