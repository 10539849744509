import React, { useState, useEffect } from 'react';
import {
    Button,
    FormGroup,
    FormControl,
    ControlLabel,
    Row,
    Col
} from 'react-bootstrap';
import { giveStatusValue } from '../../../util/venueCalendar';
import { sortByKeyReverse } from '../../../util/utility';
import _ from 'lodash'
import dayjs from 'dayjs'
import CalendarStatusTable from './CalendarStatusTable';
import callVenueApi from '../../../util/venueAPICaller';
import CalendarDrawer from './CalendarDrawer';
import showToastMessage from '../../../util/notificationUtil';
import { notify } from 'react-notify-toast';  
import { useDispatch } from 'react-redux';
import { setAvailabilityCalendarVisibility, setAvailaibilityCalendarSelectedVenue } from '../../../redux/admin/AvailaibilityCalendarActions';


const VENUE_CHUNK_LIMIT = 10
function AddVenueModalForm(props) {
  const dispatch = useDispatch()
    const [qtext, setQtext] = useState("");
    const [city, setCity] = useState("");
    const [venueEventObjModal,setVenueEventObjModal] = useState({})
    const [refresh,triggerRefresh]=useState(1)
    

    useEffect(() => {
        if (props.event && props.event.city) {
            setCity(capitalizeFirstLetter(props.event.city));
        }
    }, [props.event]);

    const changeCity = (e) => {
        setCity(e.target.value);
    };

    const queryText = (e) => {
        if (e.target.value && e.target.value !== '') {
            props.query(city, e.target.value);
        }
    };

    const select = (id) => {
        let list = [];
        list.push({
            uid: id
        });
        props.selectVenue(props.bundleid, list);
    };

    const shortList = (id) => {
        props.shortlistVenue(props.bundleid, id);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const refreshList=()=>{
        triggerRefresh(refresh+1)
      }


    useEffect(() => {
      if (props.event) {
         if (props.event?.event_date && props.venues && !_.isEmpty(props.venues)) {
          let venueIds = getvenueIdsList(props.venues);
          let venueIdsChunk = _.chunk(venueIds, VENUE_CHUNK_LIMIT);
          let filters = {
            start_date: dayjs(props.event.event_date).startOf("day").toISOString(),
            end_date: dayjs(props.event.event_date).endOf("day").toISOString(),
          };
          let promises = venueIdsChunk.map((chunk) => {
            return fetchCalendarEventsForVenues(chunk, filters);
          });
          Promise.all(promises)
            .then((responses) => {
              let allEvents = [].concat(...responses);
              let sortedList = sortEventsList(allEvents);

              let venueEventsObj = createVenueEventsObjMap(
                venueIds,
                sortedList
              );

              setVenueEventObjModal(venueEventsObj);
            })

            .catch((error) => {
              console.error(error);
            });
        }
      }
    }, [props.venues]);


      const sortEventsList=((list)=>{
        let listWithAddOns =list.map((item)=>{
         return {...item,timeStamp:dayjs(item.start_date).unix(),statusIndex:giveStatusValue(item.time_slot_status)}
        })
       let dateSortedList=sortByKeyReverse(listWithAddOns,"timeStamp")
       let statusSortedList=sortByKeyReverse(dateSortedList,"statusIndex")
       return statusSortedList
      
      })
  
      const createVenueEventsObjMap = (venueIds, eventsList) => {
        let modal = {};
        for (let venueId of venueIds) { 
          modal[venueId] = [];
          for (let event of eventsList) {
            if (venueId == event.venue_id) {
              event.venueSpaceName = event.venuespace_id?.name
              modal[venueId].push(event);
            }
          }
        }
        return modal;
      };
      const fetchCalendarEventsForVenues = async (venues, filters) => {
        if (venues && _.isArray(venues)) {
          let body = {};
          body.venue_ids = venues;
          if (filters) {
            body.filters = filters;
          }
          try {
            let res = await callVenueApi(`v1/venue-calender/event-lists`, "post", body);
  
            if (res.status == "Success") {
          
              return res.data.venuecalenderList;
            } else {
              return [];
            }
          } catch (e) {
            console.log(e);
          }
        }
        else return[]
      };

      const getvenueIdsList=(venueList)=>{
        return venueList.map((item)=>{
            return item._id 
        })}

        const handleCalendarButtonClicked=(venue)=>{
          dispatch(setAvailaibilityCalendarSelectedVenue(venue))
          dispatch(setAvailabilityCalendarVisibility(true))
        }
  


    const getVenueSegment = () => {
        let segment = null;
        if (props.venues && props.venues.length > 0) {
            segment = (
              <div
                className="list-group"
                style={{
                  height: "250px",
                  overflowY: "auto",
                  borderTop: "1px solid",
                }}
              >
                {/* {"Venue Space"}{"Availability Date Range"}{"Availability Status"} */}
                {props.venues.map((item) => (
                  <div className="list-group-item" key={item._id}>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="row">
                          <div className="col-xs-12">
                            <span>{item.name}</span>
                            <br/>
                            <span style={{fontSize: "13px"}}>{item.sub_area}</span>
                          </div>    
                        </div>
                      </div>
                      <div className='col-xs-6'>
                    
                          {venueEventObjModal[item._id] &&
                          !_.isEmpty(venueEventObjModal[item._id]) && (
                            <CalendarStatusTable
                              CalendarEvent={venueEventObjModal[item._id]}
                              venueName={item.name}
                            />
                          )}
                      </div>
 <div className="col-xs-3">
 <div className="row text-left" style={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
{!props.from && props.from == "plan-visit" &&

 <Button
                 onClick={() => select(item._id)}

             >Select</Button>
}

             <Button
                 disabled={item.isShortlisting}
                 onClick={() => shortList(item._id)}
             >{item.isShortlisting ? 'Shortlisting ...' : 'Shortlist'}</Button>
              <i style={{cursor:"pointer"}} onClick={()=>{handleCalendarButtonClicked(item)}}  class="fa fa-calendar" aria-hidden="true" ></i>
 </div>
 
</div>
                     
                    </div>
                  </div>
                 
                ))}
                 
              </div>
            );
        } else {
            segment = <div className="list-group">
                <div className="list-group-item">
                    <div className="text-center">
                        No Recommended Venues
                    </div>
                </div>
            </div>;
        }
        return segment
    }

    return (
        <div>
            <div className="list-group-item">
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <ControlLabel>
                                City
                            </ControlLabel>
                            <FormControl
                                componentClass="select"
                                placeholder="code"
                                value={city}
                                onChange={changeCity}
                            >
                                <option value=""></option>
                                {props.cities.map(item => (
                                    <option key={item.value} value={item.label}>{item.label}</option>
                                ))}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={9}>
                        <FormGroup>
                            <ControlLabel>
                                Type venue name here
                            </ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="search text"
                                defaultValue={qtext}
                                onChange={queryText}
                            >
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                {getVenueSegment()}
              
            </div>
        </div>
    );
}

export default AddVenueModalForm;



